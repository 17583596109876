import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Switch } from '@ui/switch'
import { Slider } from '@ui/slider'
import { Confirm } from '@ui/confirm'
import Assets from '@ui/assets'
import { FlexEnd, FlexRowVerticalCenter } from '@ui/layout'
import { BoldText } from '@ui/text'
import { UnitModeContainer, ModeDescription } from 'components/Dashboard'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import api from 'services/api'
import { apiErrorHandler } from 'utils/error'
import errorContext from 'contexts/error'
import authContext from 'contexts/auth'
import { useConfirm } from 'hooks/useConfirm'
import SchedulePopup from 'containers/Dashboard/Popup/Schedule'
import unitAwaiter from 'services/unit-awaiter'

interface Props {
  mode: string
  availableModes: string[]
  manualPower: number
  maxPower: number
  minPower: number
  enabled: boolean
}

const ModeSwitch = (props: Props) => {
  const [mode, changeMode] = useState<string>(props.mode)
  const [modeForDescription, changeModeForDescription] = useState<string>(props.mode)
  const [manualPower, updateManualPower] = useState<number>(props.manualPower)
  const [manualPowerTimeout, updatePowerTimeout] = useState(0)
  const [manualPowerLoading, setManualPowerLoading] = useState(false)
  const localeCtx = useContext(localeContext)
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)
  const { confirmOpened, openConfirm, confirmPayload, setConfirmPayload } = useConfirm()
  const [schedulePopupOpened, openSchedulePopup] = useState(false)

  useEffect(() => {
    try {
      if (unitAwaiter.check('mode', props.mode)) {
        changeMode(props.mode)
      }
    } catch (err) {
      apiErrorHandler(errorCtx, localeCtx, authCtx)(err)
      changeMode(props.mode)
    }

    try {
      if (unitAwaiter.check('manual-power', props.manualPower)) {
        updateManualPower(props.manualPower)
        setManualPowerLoading(false)
      }
    } catch (err) {
      apiErrorHandler(errorCtx, localeCtx, authCtx)(err)
      updateManualPower(props.manualPower)
      setManualPowerLoading(false)
    }
  }, [localeCtx, props]) // eslint-disable-line react-hooks/exhaustive-deps

  const modes = useMemo(() => props.availableModes.map(availableMode => (
    { name: __(`dashboard.modes.${availableMode}`, localeCtx.lang), id: availableMode }
  )), [props.availableModes, localeCtx])

  const modeDescription = useMemo(() => {
    switch (modeForDescription) {
      case 'intelligent':
        return <ModeDescription>{__('dashboard.modes.texts.intelligent', localeCtx.lang)}</ModeDescription>
      case 'schedule':
        return <ModeDescription>{__('dashboard.modes.texts.schedule', localeCtx.lang)}</ModeDescription>
      case 'manual':
        return <ModeDescription>{__('dashboard.modes.texts.manual', localeCtx.lang)}</ModeDescription>
      default:
        return null
    }
  }, [modeForDescription, localeCtx])

  const manualModeContent = useMemo(() => {
    const handleManualPowerChange = (newValue: number) => {
      if (manualPowerLoading) {
        return
      }
      updateManualPower(newValue)
      unitAwaiter.register('manual-power', newValue)
      clearTimeout(manualPowerTimeout)
      updatePowerTimeout(setTimeout(async () => {
        setManualPowerLoading(true)
        await api.setManualPower(newValue).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
      }, 2000))
    }

    return (
      <>
        <Slider
          max={props.maxPower}
          min={props.minPower}
          onChange={handleManualPowerChange}
          value={manualPower}
          disabled={!props.enabled || manualPowerLoading}
        />
        <FlexEnd>
          <FlexRowVerticalCenter>
            { manualPowerLoading && <Assets.SmallLoader/> }
            <BoldText>{manualPower} m<sup>3</sup>/h</BoldText>
          </FlexRowVerticalCenter>
        </FlexEnd>
      </>
    )
  }, [props.maxPower, props.minPower, props.enabled, manualPower, manualPowerLoading, manualPowerTimeout, errorCtx, localeCtx, authCtx])

  return (
    <>
      <UnitModeContainer>
        <Switch
          name="mode-switch"
          selectedOption={mode}
          values={modes}
          disabled={!props.enabled}
          onSelect={(newMode: string) => {
            if (!props.enabled) {
              return
            }
            if (newMode === 'schedule') {
              openSchedulePopup(true)
            } else if (newMode === mode) {
              return // eslint-disable-line no-useless-return
            } else {
              setConfirmPayload(newMode)
              openConfirm(true)
            }
          }}
          onHover={changeModeForDescription}
          onHoverEnd={() => changeModeForDescription(mode)}
        />
        {modeDescription}
        {mode === 'manual' && manualModeContent}
      </UnitModeContainer>
      <Confirm
        opened={confirmOpened}
        payload={confirmPayload}
        onClose={() => openConfirm(false)}
        message={__('dashboard.confirm.change-mode', localeCtx.lang)}
        onConfirm={async (newMode: string) => {
          changeMode(newMode)
          changeModeForDescription(newMode)
          unitAwaiter.register('mode', newMode)
          await api.changeMode(newMode).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
          if (schedulePopupOpened) {
            openSchedulePopup(false)
          }
        }}
      />
      <SchedulePopup
        open={schedulePopupOpened}
        onClose={() => openSchedulePopup(false)}
        isScheduleActive={mode === 'schedule'}
        onSubmit={(opts = { shouldOpenConfirm: true }) => {
          if (opts.shouldOpenConfirm) {
            setConfirmPayload('schedule')
            openConfirm(true)
          } else {
            // if there is no confirm dialog, we just close the popup right away
            openSchedulePopup(false)
          }
        }}
      />
    </>
  )
}

export default ModeSwitch
