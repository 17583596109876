import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Popup } from '@ui/popup'
import { FlexColumn, FlexEnd } from '@ui/layout'
import { Button } from '@ui/buttons'
import { Select } from '@ui/select'
import { SecondarySpan } from '@ui/text'
import TimePicker from '@ui/time-picker'
import config from 'config'
import { DateTimeSelect, ModeSelect } from 'components/Schedule'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import { SchedulePlanRange } from 'services/api/responses'
import { convertDayUnitsToTime, convertTimeToDayUnits } from 'containers/Schedule/ScheduleCalendar/utils'

interface Props {
  open: boolean
  onClose: () => void
  onCancel: () => void
  addPlan: (newPlanRange: SchedulePlanRange) => void
  popupDefaults: SchedulePlanRange
  scheduleModes: string[]
}

const SchedulePopup = (props: Props) => {
  const [dayFrom, updateDayFrom] = useState(props.popupDefaults.dayFrom)
  const [dayTo, updateDayTo] = useState(props.popupDefaults.dayTo)
  const [timeUnitFrom, updateHourFrom] = useState(props.popupDefaults.timeUnitFrom)
  const [timeUnitTo, updateHourTo] = useState(props.popupDefaults.timeUnitTo)
  const [mode, updateMode] = useState(props.popupDefaults.mode)

  const localeCtx = useContext(localeContext)

  useEffect(() => {
    updateDayFrom(props.popupDefaults.dayFrom)
    updateDayTo(props.popupDefaults.dayTo)
    updateHourFrom(props.popupDefaults.timeUnitFrom)
    updateHourTo(props.popupDefaults.timeUnitTo)
  }, [props.popupDefaults])

  const days = useMemo(
    () =>
      config.days.full.map((dayName, i) => ({ id: `${i}`, name: __(dayName, localeCtx.lang) })),
    [localeCtx.lang],
  )

  const modes = useMemo(
    () =>
      props.scheduleModes
        .filter(scheduleMode => scheduleMode !== 'off')
        .map(scheduleMode => ({ id: scheduleMode, name: __(`schedule.modes.${scheduleMode}`, localeCtx.lang) })),
    [props.scheduleModes, localeCtx],
  )

  // TODO: move this to timepicker
  // const toHours = useMemo(
  //   () =>
  //     config.hours.map((timeUnit, i) => ({
  //       id: `${i}`,
  //       name: timeUnit,
  //       disabled: i <= timeUnitFrom && Number(dayFrom) === Number(dayTo),
  //     })),
  //   [timeUnitFrom, dayFrom, dayTo],
  // )

  const toDays = useMemo(
    () =>
      config.days.full.map((dayName, i) => ({
        id: `${i}`,
        name: __(dayName, localeCtx.lang),
        // disabled: i < Number(dayFrom),
      })),
    [localeCtx],
  )

  // TODO: move this to timepicker
  // useEffect(() => {
  //   if (timeUnitTo < timeUnitFrom && Number(dayFrom) === Number(dayTo)) {
  //     updateHourTo(timeUnitFrom + 1)
  //   }
  // }, [timeUnitFrom, dayFrom, dayTo, timeUnitTo])

  // useEffect(() => {
  //   if (dayTo < dayFrom) {
  //     updateDayTo(dayFrom)
  //   }
  // }, [dayFrom, dayTo])

  return (
    <Popup
      title={__('schedule.add-plan', localeCtx.lang)}
      opened={props.open}
      onClose={() => {
        props.onClose()
        props.onCancel()
      }}>
      <FlexColumn>
        <DateTimeSelect>
          <SecondarySpan>{__('general.from', localeCtx.lang)}: </SecondarySpan>
          <Select
            name="day-from"
            values={days}
            selectedOption={dayFrom}
            onSelect={updateDayFrom}
          />
          <SecondarySpan>{__('general.to', localeCtx.lang)}: </SecondarySpan>
          <Select
            name="day-to"
            values={toDays}
            selectedOption={dayTo}
            onSelect={updateDayTo}
          />
        </DateTimeSelect>
        <DateTimeSelect>
          <SecondarySpan>{__('general.from', localeCtx.lang)}: </SecondarySpan>
          <TimePicker
            hours={convertDayUnitsToTime(timeUnitFrom).hours}
            minutes={convertDayUnitsToTime(timeUnitFrom).minutes}
            minutesStep={30}
            onChange={newTime => {
              updateHourFrom(convertTimeToDayUnits(newTime))
            }}
          />
          <SecondarySpan>{__('general.to', localeCtx.lang)}: </SecondarySpan>
          <TimePicker
            hours={convertDayUnitsToTime(timeUnitTo).hours}
            minutes={convertDayUnitsToTime(timeUnitTo).minutes}
            minutesStep={30}
            onChange={newTime => {
              updateHourTo(convertTimeToDayUnits(newTime))
            }}
          />
        </DateTimeSelect>
        <ModeSelect>
          <SecondarySpan>{__('schedule.mode', localeCtx.lang)}: </SecondarySpan>
          <Select
            name="mode"
            values={modes}
            selectedOption={mode}
            onSelect={updateMode}
          />
        </ModeSelect>
        <FlexEnd>
          <Button onClick={() => {
            props.addPlan({
              dayFrom: Number(dayFrom),
              timeUnitFrom: Number(timeUnitFrom),
              dayTo: Number(dayTo),
              timeUnitTo: Number(timeUnitTo),
              mode,
            })
            props.onClose()
          }}>{__('general.add', localeCtx.lang)}</Button>
        </FlexEnd>
      </FlexColumn>
    </Popup>
  )
}

export default SchedulePopup
