import React, { useContext } from 'react'
import { Popup } from '@ui/popup'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import Schedule from 'containers/Schedule'
import api from 'services/api'
import { apiErrorHandler } from 'utils/error'
import errorContext from 'contexts/error'
import authContext from 'contexts/auth'
import { ScheduleDescription } from 'components/Schedule'

interface Props {
  open: boolean
  onClose: () => void
  onSubmit: (opts?: { shouldOpenConfirm: boolean}) => void
  isScheduleActive: boolean
}

const SchedulePopup = (props: Props) => {
  const localeCtx = useContext(localeContext)
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)

  return (
    <Popup
      title={__('schedule.title', localeCtx.lang)}
      opened={props.open}
      onClose={props.onClose}
      fullWidth
    >
      <ScheduleDescription>{__('schedule.description', localeCtx.lang)}</ScheduleDescription>
      <Schedule
        onSubmit={async (plan: string) => {
          if (props.isScheduleActive) {
            // there was an "Update plan" action so we will send update request to save plan
            await api.setSchedulePlan(plan).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
            props.onSubmit({ shouldOpenConfirm: false })
          } else {
            props.onSubmit()
          }
        }}
        isScheduleActive={props.isScheduleActive}/>
    </Popup>
  )
}

export default SchedulePopup
