import React, { createContext, useState } from 'react'

export interface AuthContext {
  authenticated: boolean
  setAuthenticated: (authenticated: boolean) => void
}

const defaultCtx = {
  authenticated: false,
  setAuthenticated: () => {},
}

const authContext = createContext<AuthContext>(defaultCtx)

export const AuthProvider = ({ children }: {children: React.ReactNode}) => {
  const [authenticated, setAuthenticated] = useState<boolean>(defaultCtx.authenticated)

  const ctx: AuthContext = {
    authenticated,
    setAuthenticated,
  }

  return (
    <authContext.Provider value={ctx}>
      {children}
    </authContext.Provider>
  )
}

export default authContext
