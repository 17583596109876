import styled from 'styled-components'
import { Container, FlexBetweenVerticalCenter } from '@ui/layout'

export const HeaderContainer = styled(Container)`
  padding-bottom: 0;
`

export const LogoContainer = styled(FlexBetweenVerticalCenter)`
  z-index: 20;
  position: relative;
`
