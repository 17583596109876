import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import App from 'App'
import config from 'config'
import '@ui/css/balloon.css'
import '@ui/css/_reset.css'
import '@ui/css/fonts.css'
import '@ui/css/circle-progressbar.css'

if (config.env === 'production') {
  Sentry.init({
    dsn: config.sentry.dsn,
    release: `${config.app.name}@${config.app.gitSha}`,
  })
}

ReactDOM.render(<App/>, document.getElementById('root'))
