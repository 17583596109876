import styled from 'styled-components'
import { boxShadow, media } from './_common'
import { BoldText } from '@ui/text'
import { FlexColumn, FlexEnd } from '@ui/layout'
import { Theme } from '@ui/index'

const border = (color: string) => `8px solid ${color}`

export const Card = styled(FlexColumn)`
  padding: 1rem .2rem;
  margin: .5rem 1rem;
  border-left: ${({ color }: { color: string }) => border(color)};
  ${({ theme }) => boxShadow(theme)};
  border-radius: 5px;
  box-sizing: border-box;
  justify-content: center;
  
  // @ts-ignore - fucking typescript again, ugh
  ${({ scaled }: { scaled: boolean }) => scaled ? 'transform: scale(1.1)' : ''};
  transition: all 150ms linear;

  ${BoldText} {
    font-size: ${({ theme }: { theme: Theme }) => theme.sizes.xs}rem;
  }
  
  ${FlexEnd} {
    flex-grow: 1;
    align-items: flex-end;
  }
  
  ${media.medium} {
    border-left: none;
    border-top: ${({ color }: { color: string }) => border(color)};
  }
`
