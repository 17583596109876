import React, { useContext, useEffect, useState } from 'react'
import Unit from 'containers/Dashboard/Unit'
import ModeSwitch from 'containers/Dashboard/ModeSwitch'
import Settings from 'containers/Dashboard/Settings'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import dataContext from 'contexts/data'
import { DashboardContainer } from 'components/Dashboard'
import { ApiDashboardResponse } from 'services/api/responses'

const Dashboard = (props: { dashboard: ApiDashboardResponse, showTurnOnSwitch: boolean }) => {
  const localeCtx = useContext(localeContext)
  const dataCtx = useContext(dataContext)
  const [unitStatusChangeInProgress, setUnitStatusChangeInProgress] = useState(false)

  const isModeSwitchEnabled = () => !unitStatusChangeInProgress
    && !props.dashboard['stop-active']
    && !props.dashboard['holiday-active']
    && props.dashboard['boost-remaining'] === 0

  useEffect(() => {
    dataCtx.set({ ...dataCtx.data, title: __('navigation.dashboard', localeCtx.lang) })
  }, [localeCtx]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardContainer enabled={!unitStatusChangeInProgress && !props.dashboard['stop-active']}>
      <Unit
        name={dataCtx.data.unit.name}
        type={dataCtx.data.unit.type}
        turnedOn={!props.dashboard['stop-active']}
        showTurnOnSwitch={props.showTurnOnSwitch}
        statusChangeInProgress={unitStatusChangeInProgress}
        onStatusChange={setUnitStatusChangeInProgress}
      />
      <ModeSwitch
        mode={props.dashboard.authority}
        manualPower={props.dashboard['flow-requested']}
        maxPower={props.dashboard['flow-max']}
        minPower={props.dashboard['flow-min']}
        availableModes={props.dashboard['authority-available']}
        enabled={isModeSwitchEnabled()}
      />
      <Settings
        capabilities={props.dashboard.capabilities}
        circulation={props.dashboard['circulation-active']}
        nightMode={props.dashboard['silent-active']}
        fireplace={props.dashboard['fireplace-active']}
        boostDuration={props.dashboard['boost-duration']}
        boostRemaining={props.dashboard['boost-remaining']}
        holiday={props.dashboard['holiday-active']}
        enabled={!unitStatusChangeInProgress && !props.dashboard['stop-active']}
      />
    </DashboardContainer>
  )
}

export default Dashboard
