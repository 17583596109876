import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  addPlanRange,
  convertPlanToString,
  deletePlanRange,
  parsePlan,
  removeDnDPlan,
  ScheduleModeDnd,
} from './ScheduleCalendar/utils'
import { HorizontalCalendar, VerticalCalendar } from './ScheduleCalendar'
import { FlexEnd } from '@ui/layout'
import { Button, FloatingPrimaryButton } from '@ui/buttons'
import Assets from '@ui/assets'
import { ScreenSize } from '@ui/_common'
import { Confirm } from '@ui/confirm'
import { useConfirm } from 'hooks/useConfirm'
import SchedulePopup from 'containers/Schedule/SchedulePopup'
import api from 'services/api'
import localeContext from 'contexts/locale'
import errorContext from 'contexts/error'
import { __ } from 'services/locale'
import { SchedulePlan, SchedulePlanRange } from 'services/api/responses'
import { apiErrorHandler } from 'utils/error'
import authContext from 'contexts/auth'
import { useWindowDimensions } from 'hooks/useWindow'
import { BottomContainer, ModesLegend, ScheduleButtonDescription } from 'components/Schedule'

interface Props {
  onSubmit: (plan: string) => Promise<void>
  isScheduleActive: boolean
}

const Schedule = (props: Props) => {
  const [isPopupOpen, openPopup] = useState(false)
  const [popupDefaults, setPopupDefaults] = useState<SchedulePlanRange>({
    dayFrom: 0, dayTo: 0, timeUnitFrom: 0, timeUnitTo: 1, mode: 'auto',
  })
  const [plan, updatePlan] = useState<SchedulePlan>(parsePlan(''))
  const [modes, setModes] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const localeCtx = useContext(localeContext)
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)
  const { width } = useWindowDimensions()
  const { confirmOpened, openConfirm, confirmPayload, setConfirmPayload } = useConfirm()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const planData = await api.getSchedule().catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
      if (planData) {
        try {
          if(planData.modes) {
            for(var idx=0; idx < planData.modes.length; idx++) if(planData.modes[idx] == 'nom') {
              planData.modes.splice(idx, 1)
              break
            }
          }          
          updatePlan(parsePlan(planData.plan))
        } catch (ex) {
          errorCtx.setErrorMessage(__('schedule.overlap-error', localeCtx.lang))
        }
        setModes(planData.modes)
      }
      setLoading(false)
    }
    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {
        width < ScreenSize.M ? (
          <VerticalCalendar
            loading={loading}
            weekPlan={plan}
            removePlan={(planRange: SchedulePlanRange) => {
              setConfirmPayload(planRange)
              openConfirm(true)
            }}
            openPlanPopup={(newDefaults: SchedulePlanRange) => {
              setPopupDefaults(newDefaults)
              openPopup(true)
            }}
          />
        ) : (
          <HorizontalCalendar
            weekPlan={plan}
            loading={loading}
            addPlan={(newPlan: SchedulePlanRange) => {
              // add plan
              try {
                const updatedPlan = addPlanRange(newPlan, plan)
                updatePlan(updatedPlan)
                // show popup for possible edit
                setPopupDefaults(newPlan)
                openPopup(true)
                errorCtx.setErrorMessage('')
              } catch (ex) {
                errorCtx.setErrorMessage(__('schedule.overlap-error', localeCtx.lang))
              }
            }}
            removePlan={(planRange: SchedulePlanRange) => {
              setConfirmPayload(planRange)
              openConfirm(true)
            }}
            openPlanPopup={(newDefaults: SchedulePlanRange) => {
              setPopupDefaults(newDefaults)
              openPopup(true)
            }}
          />
        )
      }
      <ScheduleButtonDescription>
        {__(props.isScheduleActive ? 'schedule.update-btn-description' : 'schedule.enable-btn-description', localeCtx.lang)}
      </ScheduleButtonDescription>
      <BottomContainer>
        <ModesLegend modes={useMemo(() => modes.filter(mode => mode !== 'off'), [modes])}/>
        <Button
          onClick={useMemo(() => () => props.onSubmit(convertPlanToString(plan)), [plan, props])}
        >
          {__(props.isScheduleActive ? 'schedule.update' : 'schedule.set', localeCtx.lang)}
        </Button>
      </BottomContainer>
      <FlexEnd>
        <FloatingPrimaryButton
          onClick={useMemo(() => () => openPopup(true), [])}
          floatingIcon={<Assets.PlusIcon/>}
        >
          {__('schedule.add-plan', localeCtx.lang)}
        </FloatingPrimaryButton>
      </FlexEnd>
      <SchedulePopup
        open={isPopupOpen}
        popupDefaults={popupDefaults}
        scheduleModes={modes}
        onClose={() => openPopup(false)}
        onCancel={() => updatePlan(removeDnDPlan(plan))}
        addPlan={async (newPlanRange: SchedulePlanRange) => {
          try {
            const updatedPlan = addPlanRange(newPlanRange, plan)
            updatePlan(updatedPlan)
            const stringPlan = convertPlanToString(updatedPlan)
            // we don't want to automatically save new changes if mode is active,
            // it will get saved after pressing "Update" button
            if (!props.isScheduleActive) {
              await api.setSchedulePlan(stringPlan).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
            }
          } catch (ex) {
            errorCtx.setErrorMessage(__('schedule.overlap-error', localeCtx.lang))
          }
        }}
      />
      <Confirm
        opened={confirmOpened}
        payload={confirmPayload}
        onClose={() => openConfirm(false)}
        message={__('schedule.confirm.delete', localeCtx.lang)}
        onConfirm={async (planRange: SchedulePlanRange) => {
          const updatedPlan = deletePlanRange(planRange, plan)
          updatePlan(updatedPlan)
          if (planRange.mode !== ScheduleModeDnd) {
            const stringPlan = convertPlanToString(updatedPlan)
            await api.setSchedulePlan(stringPlan).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
          }
        }}
      />
    </>
  )
}

export default Schedule
