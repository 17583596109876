import React from 'react'
import styled, { css } from 'styled-components'
import { primaryHover, boxShadow, media } from './_common'
import loaderLight from '@ui/assets/loader-light.svg'

interface Props {
  children: string
  loading?: boolean
  onClick?: () => void
}

const Styles = css`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.sizes.s}rem;
  text-align: center;
  padding: .8rem 3rem;
  border-radius: .3rem;
  border: none;
  ${({ theme }) => boxShadow(theme)}
  ${({ theme }) => primaryHover(theme)}
  cursor: pointer;

  ${({ loading }: {loading?: number}) =>
    loading
      ? `
        color: transparent;
        background-image: url(${loaderLight});
        background-repeat: no-repeat;
        background-position: center;
      `
      : ''
}}

  &, &:hover, &:focus {
    outline: none;
  }

  ${media.small} {
    width: 100%;
    padding: .8rem;
  }
`

export const StyledButton = styled.button`
  ${Styles}
`

const StyledFloatingButton = styled(StyledButton)`
  display: none;
  z-index: 20;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  box-shadow: none;

  ${media.medium} {
    display: flex;
  }

  ${media.small} {
    right: 1rem;
    width: 4rem;
  }
`

export const StyledButtonLink = styled.a`
  ${Styles};
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
`

export const ButtonLink = (props: any) => (
  <StyledButtonLink {...props}>
    {props.children}
  </StyledButtonLink>
)

export const Button = (props: Props) => (
  <StyledButton disabled={props.loading} loading={props.loading ? 1 : 0} onClick={props.onClick}>
    {props.children}
  </StyledButton>
)

export const FloatingPrimaryButton = (props: Props & { floatingIcon: React.ReactNode}) => (
  <StyledFloatingButton disabled={props.loading} loading={props.loading ? 1 : 0} onClick={props.onClick}>
    {props.floatingIcon}
  </StyledFloatingButton>
)
