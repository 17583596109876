import React, { useContext, useState, useEffect } from 'react'
import { Popup } from '@ui/popup'
import { FlexColumn, FlexEnd, FlexRowCenter } from '@ui/layout'
import { Switch } from '@ui/switch'
import { Input } from '@ui/input'
import { Button } from '@ui/buttons'
import { Confirm } from '@ui/confirm'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import { Time, secondsToTime } from 'utils/time'
import { useConfirm } from 'hooks/useConfirm'
import config from 'config'

interface Props {
  open: boolean
  onClose: () => void
  submit: (newDuration: number) => void
  duration: number
}

const BoostPopup = (props: Props) => {
  const localeCtx = useContext(localeContext)
  const convertedTime = secondsToTime(props.duration)
  const [durationType, setDurationType] = useState(convertedTime.time)
  const [duration, setDuration] = useState<number|string>(convertedTime.value)
  const { confirmOpened, openConfirm, confirmPayload } = useConfirm()

  useEffect(() => {
    const { time, value } = secondsToTime(props.duration)
    setDurationType(time)
    setDuration(value)
  }, [props.duration])

  return (
    <>
      <Popup
        title={__('dashboard.settings.boost', localeCtx.lang)}
        opened={props.open}
        onClose={props.onClose}
      >
        <FlexColumn>
          <FlexRowCenter>
            <Switch
              name="boost-duration"
              selectedOption={durationType}
              values={[
                { name: __('general.hour-short', localeCtx.lang), id: Time.hours },
                { name: __('general.min-short', localeCtx.lang), id: Time.minutes },
              ]}
              onSelect={setDurationType}
            />
          </FlexRowCenter>
          <Input
            placeholder="10"
            type="number"
            value={duration}
            onChange={evt => setDuration(evt.target.value)}
          />
          <FlexEnd>
            <Button
              onClick={() => {
                const durationInSeconds = Number(duration || 0) * durationType
                if (durationInSeconds >= config.boostDurationAlertThreshold) {
                  openConfirm(true)
                  return
                }
                props.submit(Number(duration || 0) * durationType)
              }}
            >
              {__('general.set', localeCtx.lang)}
            </Button>
          </FlexEnd>
        </FlexColumn>
      </Popup>
      <Confirm
        opened={confirmOpened}
        payload={confirmPayload}
        onClose={() => openConfirm(false)}
        message={__('schedule.boost-warning', localeCtx.lang)}
        onConfirm={() => props.submit(Number(duration || 0) * durationType)}
      />
    </>
  )
}

export default BoostPopup
