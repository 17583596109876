import { withTheme } from 'styled-components'
import React, { useContext, useEffect, useState } from 'react'
import { Card } from '@ui/card'
import { FlexBetweenVerticalCenter } from '@ui/layout'
import Assets from '@ui/assets'
import { BoldText } from '@ui/text'
import ProgressBar from '@ui/progress-bar'
import {
  CardsContainer,
  CardTitle,
  CardValue, FilterItemContainer,
  FiltersContainer,
  FilterTitle,
  FilterValue,
  UnitInfoContainer,
} from 'components/Dashboard'
import { Theme } from '@ui'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import { FilterStatus } from 'services/api/responses'

interface Props {
  theme: Theme
  temperatures: number[]
  humidity: number|null
  power: number
  co2: number
  filters: {
    fresh: FilterStatus
    waste: FilterStatus
  }
}

const getTemperature = (index: number, temperatures: number[]) => temperatures[index] || '-'

const getFilterStatusColor = (filterStatus: string, theme: Theme) => {
  switch (filterStatus) {
    case 'warn': return theme.colors.warning
    case 'eol': return theme.colors.error
    case 'good':
    case 'new':
    default: return theme.colors.new
  }
}

const getFilterStatusIcon = (filterStatus: string, theme: Theme) => {
  switch (filterStatus) {
    case 'new': return { icon: <Assets.AirFilterIcon/>, color: theme.colors.new }
    case 'warn': return { icon: <Assets.WarningIcon/>, color: theme.colors.warning }
    case 'eol': return { icon: <Assets.ErrorIcon/>, color: theme.colors.error }
    case 'good':
    default: return { icon: <Assets.AirFilterIcon/>, color: theme.colors.gray1 }
  }
}

const dispatchHoverInEvent = (id: string) =>
  window.dispatchEvent(new CustomEvent('card-in', { detail: { id } }))

const dispatchHoverOutEvent = (id: string) =>
  window.dispatchEvent(new CustomEvent('card-out', { detail: { id } }))

const UnitInfo = (props: Props) => {
  const localeCtx = useContext(localeContext)
  const freshStatusIcon = getFilterStatusIcon(props.filters.fresh.status, props.theme)
  const wasteStatusIcon = getFilterStatusIcon(props.filters.waste.status, props.theme)

  const [hoverHouseElement, setHoverHouseElement] = useState('')

  // register listeners for events from SVG house
  useEffect(() => {
    const houseInHandler = ((evt: CustomEvent) => {
      setHoverHouseElement(evt.detail.id)
    }) as EventListener
    const houseOutHandler = () => setHoverHouseElement('')

    window.addEventListener('house-in', houseInHandler)
    window.addEventListener('house-out', houseOutHandler)

    return () => {
      window.removeEventListener('house-in', houseInHandler)
      window.removeEventListener('house-out', houseOutHandler)
    }
  }, [])

  return (
    <UnitInfoContainer>
      <CardsContainer>
        <Card
          color={props.theme.colors.primary}
          scaled={hoverHouseElement === 'outdoor-line'}
          onMouseEnter={() => dispatchHoverInEvent('outdoor-line')}
          onMouseLeave={() => dispatchHoverOutEvent('outdoor-line')}
        >
          <FlexBetweenVerticalCenter>
            <CardTitle>
              <Assets.SunIcon/>
              <BoldText>{__('dashboard.unitinfo.outdoorTemperature', localeCtx.lang)}</BoldText>
            </CardTitle>
            <CardValue>{getTemperature(0, props.temperatures)}&nbsp;°C</CardValue>
          </FlexBetweenVerticalCenter>
        </Card>
        <Card
          color={props.theme.colors.green}
          scaled={hoverHouseElement === 'inhale-line'}
          onMouseEnter={() => dispatchHoverInEvent('inhale-line')}
          onMouseLeave={() => dispatchHoverOutEvent('inhale-line')}
        >
          <FlexBetweenVerticalCenter>
            <CardTitle>
              <Assets.OutdoorAirIcon/>
              <BoldText>{__('dashboard.unitinfo.inhale', localeCtx.lang)}</BoldText>
            </CardTitle>
            <CardValue>{getTemperature(1, props.temperatures)}&nbsp;°C</CardValue>
          </FlexBetweenVerticalCenter>
        </Card>
        <Card
          color={props.theme.colors.red}
          scaled={hoverHouseElement === 'indoor-line'}
          onMouseEnter={() => dispatchHoverInEvent('indoor-line')}
          onMouseLeave={() => dispatchHoverOutEvent('indoor-line')}
        >
          <FlexBetweenVerticalCenter>
            <CardTitle>
              <Assets.HomeIcon/>
              <BoldText>{__('dashboard.unitinfo.indoorTemperature', localeCtx.lang)}</BoldText>
            </CardTitle>
            <CardValue>{getTemperature(2, props.temperatures)}&nbsp;°C</CardValue>
          </FlexBetweenVerticalCenter>
        </Card>
        <Card
          color={props.theme.colors.orange}
          scaled={hoverHouseElement === 'exhale-line'}
          onMouseEnter={() => dispatchHoverInEvent('exhale-line')}
          onMouseLeave={() => dispatchHoverOutEvent('exhale-line')}
        >
          <FlexBetweenVerticalCenter>
            <CardTitle>
              <Assets.IndoorAirIcon/>
              <BoldText>{__('dashboard.unitinfo.exhale', localeCtx.lang)}</BoldText>
            </CardTitle>
            <CardValue>{getTemperature(3, props.temperatures)}&nbsp;°C</CardValue>
          </FlexBetweenVerticalCenter>
        </Card>
      </CardsContainer>
      <FiltersContainer>
        { (props.humidity !== null && props.humidity !== 0) && (
          <FlexBetweenVerticalCenter>
            <FilterTitle
              scaled={hoverHouseElement === 'humidity-wrapper'}
              onMouseEnter={() => dispatchHoverInEvent('humidity-wrapper')}
              onMouseLeave={() => dispatchHoverOutEvent('humidity-wrapper')}
            >
              <Assets.DropIcon/>
              {__('dashboard.unitinfo.humidity', localeCtx.lang)}
            </FilterTitle>
            <FilterValue>{props.humidity || '-'}&nbsp;%</FilterValue>
          </FlexBetweenVerticalCenter>
        )}
        <FlexBetweenVerticalCenter>
          <FilterTitle>
            <Assets.AirIcon/>
            CO<sub>2</sub>
          </FilterTitle>
          <FilterValue>{props.co2 || '-'}&nbsp;ppm</FilterValue>
        </FlexBetweenVerticalCenter>
        <FilterItemContainer>
          <FilterTitle>
            <Assets.ValveIcon/>
            {__('dashboard.unitinfo.power', localeCtx.lang)}
          </FilterTitle>
          <FilterValue>{props.power}&nbsp;m<sup>3</sup>/h</FilterValue>
        </FilterItemContainer>
        <FilterItemContainer>
          <FilterTitle>
            {freshStatusIcon.icon}
            {__('dashboard.unitinfo.filters.fresh-status', localeCtx.lang)}
          </FilterTitle>
          <ProgressBar
            percentage={props.filters.fresh.health}
            color={getFilterStatusColor(props.filters.fresh.status, props.theme)}
          />
        </FilterItemContainer>
        <FilterItemContainer>
          <FilterTitle>
            {wasteStatusIcon.icon}
            {__('dashboard.unitinfo.filters.waste-status', localeCtx.lang)}
          </FilterTitle>
          <ProgressBar
            percentage={props.filters.waste.health}
            color={getFilterStatusColor(props.filters.waste.status, props.theme)}
          />
        </FilterItemContainer>
      </FiltersContainer>
    </UnitInfoContainer>
  )
}

export default withTheme(UnitInfo)
