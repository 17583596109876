import { AnyObject } from '../../../utils'

export class UnauthorizedError extends Error {
  private readonly response: AnyObject

  public constructor(response?: AnyObject) {
    super()
    this.response = response || {}
  }
}
