import { AnyObject, getObjVal } from 'utils'
import { secondsToTime, Time } from 'utils/time'
import config from 'config'

const languages: AnyObject = config.locale.available.reduce((allLangs: AnyObject, currentLang: string) => {
  allLangs[currentLang] = require(`locales/${currentLang}.json`)
  return allLangs
}, {})

// eslint-disable-next-line no-underscore-dangle
export const __ = (key: string, lang: string): string => {
  try {
    const translation = getObjVal(languages[lang], key)
    if (!translation) {
      throw new Error('translation not found')
    }
    return translation
  } catch {
    // eslint-disable-next-line no-console
    console.error(`Translation not defined for key "${key}"in language "${lang}"`)
    return key
  }
}

export const getHumanReadableSeconds = (seconds: number, lang: string) => {
  const { time, value } = secondsToTime(seconds)
  if (time === Time.minutes) {
    return `${seconds > 60 ? value : '< 1'} ${__('general.min-short', lang)}`
  }
  return `${value} ${__('general.hour-short', lang)}`
}
