import { Theme } from '@ui'

const theme: Theme = {
  colors: {
    primary: '#3090DC',
    green: '#B1D182',
    green2: '#2ecc71',
    orange: '#ECB172',
    black: '#343434',
    yellow: '#ECD072',
    lightBlue: '#C9E1F4',
    lightBlue2: '#82BCEA',
    red: '#EC8972',
    gray1: '#B4BABF',
    gray2: '#ACADAF',
    gray3: '#D9DCDE',
    gray4: '#D6D6D6',
    gray5: '#F5F5F5',
    white: '#FFFFFF',
    schedule: {
      min: '#00a8ff',
      nom: '#fa983a',
      auto: '#3ae374',
      boost: '#d63031',
    },
    new: '#2ecc71',
    warning: '#E3B310',
    error: '#E64C28',
  },
  sizes: {
    /* eslint-disable id-length */
    xs: 0.8,
    s: 0.9,
    m: 1.1,
    l: 1.3,
    xl: 1.5,
    /* eslint-enable id-length */
  },
}

export default theme
