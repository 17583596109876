import React, { useContext, useState, useEffect } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { SecondarySpan } from '@ui/text'
import Assets from '@ui/assets'
import { FlexRowVerticalCenter } from '@ui/layout'
import { Confirm } from '@ui/confirm'
import { ToggleRow } from 'components/Dashboard'
import BoostPopup from 'containers/Dashboard/Popup/Boost'
import localeContext from 'contexts/locale'
import { __, getHumanReadableSeconds } from 'services/locale'
import { Mode } from 'services/api/inputs'
import api from 'services/api'
import { apiErrorHandler } from 'utils/error'
import errorContext from 'contexts/error'
import authContext from 'contexts/auth'
import { useConfirm } from 'hooks/useConfirm'
import config from 'config'
import unitAwaiter from 'services/unit-awaiter'
import 'react-circular-progressbar/dist/styles.css'
import { Capability } from 'services/api/responses'

interface Props {
  capabilities: Capability[]
  circulation: boolean
  nightMode: boolean
  fireplace: boolean
  holiday: boolean
  boostDuration: number
  boostRemaining: number
  enabled: boolean
}

const hasCapability = (type: Capability, available: Capability[]) => available.includes(type)

const Settings = (props: Props) => {
  const localeCtx = useContext(localeContext)
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)

  const [circulation, toggleCirculation] = useState<boolean>(props.circulation)
  const [nightMode, toggleNightMode] = useState<boolean>(props.nightMode)
  const [fireplace, toggleFireplace] = useState<boolean>(props.fireplace)
  const [holiday, toggleHoliday] = useState<boolean>(props.holiday)

  const [boost, toggleBoost] = useState<boolean>(props.boostRemaining > 0)
  const [boostModalOpen, toggleBoostModal] = useState<boolean>(false)
  const [boostDuration, changeBoostDuration] = useState(props.boostDuration)

  const [circulationLoading, setCirculationLoading] = useState(false)
  const [nightModeLoading, setNightModeLoading] = useState(false)
  const [fireplaceLoading, setFireplaceLoading] = useState(false)
  const [holidayLoading, setHolidayLoading] = useState(false)
  const [boostLoading, setBoostLoading] = useState(false)

  const { confirmOpened, openConfirm, confirmPayload, setConfirmPayload } = useConfirm()

  useEffect(() => {
    try {
      if (unitAwaiter.check('circulation', props.circulation)) {
        toggleCirculation(props.circulation)
        setCirculationLoading(false)
      }
    } catch (err) {
      apiErrorHandler(errorCtx, localeCtx, authCtx)(err)
      toggleCirculation(props.circulation)
      setCirculationLoading(false)
    }

    try {
      if (unitAwaiter.check('night-mode', props.nightMode)) {
        toggleNightMode(props.nightMode)
        setNightModeLoading(false)
      }
    } catch (err) {
      apiErrorHandler(errorCtx, localeCtx, authCtx)(err)
      toggleNightMode(props.nightMode)
      setNightModeLoading(false)
    }

    try {
      if (unitAwaiter.check('fireplace', props.fireplace)) {
        toggleFireplace(props.fireplace)
        setFireplaceLoading(false)
      }
    } catch (err) {
      apiErrorHandler(errorCtx, localeCtx, authCtx)(err)
      toggleFireplace(props.fireplace)
      setFireplaceLoading(false)
    }

    try {
      if (unitAwaiter.check('holiday', props.holiday)) {
        toggleHoliday(props.holiday)
        setHolidayLoading(false)
      }
    } catch (err) {
      apiErrorHandler(errorCtx, localeCtx, authCtx)(err)
      toggleHoliday(props.holiday)
      setHolidayLoading(false)
    }

    try {
      if (unitAwaiter.check('boost', props.boostRemaining)) {
        changeBoostDuration(props.boostDuration)
        toggleBoost(props.boostRemaining > 0)
        setBoostLoading(false)
      }
    } catch (err) {
      apiErrorHandler(errorCtx, localeCtx, authCtx)(err)
      changeBoostDuration(props.boostDuration)
      toggleBoost(props.boostDuration > 0)
      setBoostLoading(false)
    }
  }, [props]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {hasCapability('circulation', props.capabilities)
      && <ToggleRow
        loading={circulationLoading}
        checked={circulation}
        onChange={async () => {
          toggleCirculation(!circulation)
          setCirculationLoading(true)
          unitAwaiter.register('circulation', !circulation)
          await api.enableMode(Mode.circulation, !circulation).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
        }}
        title={__('dashboard.settings.circulation', localeCtx.lang)}
        info={__('dashboard.settings.circulation-tooltip', localeCtx.lang)}
        disabled={!props.enabled}
      />
      }
      {hasCapability('silent', props.capabilities)
      && <ToggleRow
        loading={nightModeLoading}
        checked={nightMode}
        onChange={async () => {
          toggleNightMode(!nightMode)
          setNightModeLoading(true)
          unitAwaiter.register('night-mode', !nightMode)
          await api.enableMode(Mode.nightMode, !nightMode).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
        }}
        title={__('dashboard.settings.nightmode', localeCtx.lang)}
        info={__('dashboard.settings.nightmode-tooltip', localeCtx.lang)}
        disabled={!props.enabled}
      />
      }
      {hasCapability('fireplace', props.capabilities)
      && <ToggleRow
        loading={fireplaceLoading}
        checked={fireplace}
        onChange={async () => {
          toggleFireplace(!fireplace)
          setFireplaceLoading(true)
          unitAwaiter.register('fireplace', !fireplace)
          await api.enableMode(Mode.fireplace, !fireplace).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
        }}
        title={__('dashboard.settings.fireplace', localeCtx.lang)}
        info={__('dashboard.settings.fireplace-tooltip', localeCtx.lang)}
        disabled={!props.enabled}
      />
      }
      {hasCapability('holiday', props.capabilities)
      && <ToggleRow
        loading={holidayLoading}
        checked={holiday}
        onChange={async () => {
          toggleHoliday(!holiday)
          setHolidayLoading(true)
          unitAwaiter.register('holiday', !holiday)
          await api.enableMode(Mode.holiday, !holiday).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
        }}
        title={__('dashboard.settings.holiday', localeCtx.lang)}
        info={__('dashboard.settings.holiday-tooltip', localeCtx.lang)}
        disabled={!props.enabled}
      />
      }
      {hasCapability('boost', props.capabilities)
      && <ToggleRow
        loading={boostLoading}
        checked={boost}
        onChange={async () => {
          if (!boost && boostDuration === 0) {
            toggleBoostModal(true)
            return
          } else if (boostDuration >= config.boostDurationAlertThreshold) {
            setConfirmPayload(boostDuration)
            openConfirm(true)
            return
          }
          toggleBoost(!boost)
          setBoostLoading(true)
          unitAwaiter.register('boost', boost ? 0 : boostDuration)
          await api.setBoost(boost ? 0 : boostDuration).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
        }}
        title={__('dashboard.settings.boost', localeCtx.lang)}
        info={__('dashboard.settings.boost-tooltip', localeCtx.lang)}
        disabled={!props.enabled}
      >
        {boostDuration > 0 && (
          <FlexRowVerticalCenter onClick={() => toggleBoostModal(true)}>
            {props.boostRemaining > 0 ? (
                <>
                  <CircularProgressbar
                    value={(props.boostRemaining / props.boostDuration) * 100}
                    strokeWidth={15}
                    counterClockwise
                  />
                  <SecondarySpan>
                    {getHumanReadableSeconds(props.boostRemaining, localeCtx.lang)}&nbsp;
                  </SecondarySpan>
                </>
            )
              : <SecondarySpan>{getHumanReadableSeconds(boostDuration, localeCtx.lang)}&nbsp;</SecondarySpan>
            }
            <Assets.EditIcon/>
          </FlexRowVerticalCenter>
        )}
      </ToggleRow>
      }
      <BoostPopup
        open={boostModalOpen}
        onClose={() => toggleBoostModal(false)}
        duration={boostDuration}
        submit={async (newDuration: number) => {
          changeBoostDuration(newDuration)
          toggleBoost(newDuration > 0)
          toggleBoostModal(false)
          setBoostLoading(true)
          unitAwaiter.register('boost', newDuration)
          await api.setBoost(newDuration).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
        }}
      />
      <Confirm
        opened={confirmOpened}
        payload={confirmPayload}
        onClose={() => openConfirm(false)}
        message={__('schedule.boost-warning', localeCtx.lang)}
        onConfirm={async () => {
          toggleBoost(!boost)
          setBoostLoading(true)
          unitAwaiter.register('boost', boost ? 0 : boostDuration)
          await api.setBoost(boost ? 0 : boostDuration).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
        }}
      />
    </>
  )
}

export default Settings
