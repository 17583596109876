import React, { useContext, useState } from 'react'
import { Block } from '../../@ui/layout'
import PasswordChangePopup from './Popup/PasswordChange'
import { ButtonLink } from '@ui/buttons'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'

const PasswordChange = () => {
  const localeCtx = useContext(localeContext)
  const [isPopupOpen, openPopup] = useState(false)

  return (
    <Block>
      <ButtonLink
        href="#"
        onClick={(evt: any) => {
          evt.preventDefault()
          openPopup(true)
        }}
      >
        {__('profile.password-change.title', localeCtx.lang)}
      </ButtonLink>
      <PasswordChangePopup
        onClose={() => openPopup(false)}
        open={isPopupOpen}
        text=""
        title={__('profile.password-change.title', localeCtx.lang)}
      />
    </Block>
  )
}

export default PasswordChange
