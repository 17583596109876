import { ApiResponsePoolable } from 'services/api/responses'
import config from 'config'

type ApiHandler = (generation: number|undefined) => Promise<ApiResponsePoolable|void>

interface PoolOptions {
  refresh: number
  initialCall: boolean
}

interface PoolsList {
  [poolName: string]: {
    gen: number
    interval: number|null
  }
}

type UnsubscribePool = () => void

// TODO: use workers for this

class Pool {
  private pools: PoolsList = {}

  public repeat = (
    poolName: string,
    handler: ApiHandler,
    opts: PoolOptions = { refresh: config.defaultPoolRefresh, initialCall: true },
  ): UnsubscribePool => {
    if (this.pools[poolName]) {
      throw new Error(`Pool "${poolName}" is already registered`)
    }

    // interval handler that is called repeatably
    const intervalHandler = async () => {
      const res = await handler(this.pools[poolName].gen)
      if (res && typeof res.gen !== 'undefined') {
        if (!this.pools[poolName]) {
          this.pools[poolName] = {
            gen: 0,
            interval: null,
          }
        }
        this.pools[poolName].gen = res.gen + 1
      }
    }

    // register pool
    this.pools[poolName] = {
      gen: 0,
      interval: this.schedulePool(intervalHandler, opts.refresh),
    }

    // initial call
    if (opts.initialCall) {
      intervalHandler()
    }

    // unsubscribe handler
    return this.unschedulePool(poolName)
  }

  private schedulePool = (handler: () => Promise<void>, refresh: number) =>
    setInterval(handler, refresh)

  private unschedulePool = (poolName: string): UnsubscribePool => () => {
    if (this.pools[poolName].interval) {
      clearInterval(this.pools[poolName].interval!)
      delete this.pools[poolName]
    }
  }
}

export default new Pool()
