import React, { useContext, useEffect, useState } from 'react'
import Login from 'containers/Login'
import Loader from 'components/Loader'
import api from 'services/api'
import authContext from 'contexts/auth'

interface Props {
  children: React.ReactNode
}

const Authenticated = ({ children }: Props): React.ReactElement => {
  const [loading, setLoading] = useState(true)
  const authCtx = useContext(authContext)

  useEffect(() => {
    const authenticate = async () => {
      // send test request to verify if user is logged in
      try {
        await api.getStatus()
        authCtx.setAuthenticated(true)
      } catch (err) {
        authCtx.setAuthenticated(false)
      } finally {
        setLoading(false)
      }
    }
    authenticate()
  }, [authCtx])

  if (loading) {
    return <Loader/>
  }

  if (!authCtx.authenticated) {
    return <Login/>
  }

  // continue to protected part
  return <>{children}</>
}

export default Authenticated
