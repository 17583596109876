import styled from 'styled-components'
import React, { ReactNode, useMemo, useState } from 'react'
import { media } from '@ui/_common'
import { BoldText } from '@ui/text'
import { Theme } from '@ui'

export const FlexContainer = styled.div`
  display: flex;
`

export const FlexColumn = styled(FlexContainer)`
  flex-direction: column;
`

export const FlexColumnFullHeight = styled(FlexColumn)`
  height: 100%;
`

export const FlexColumnBetween = styled(FlexColumn)`
  justify-content: space-between;
`

export const FlexRowVerticalCenter = styled(FlexContainer)`
  align-items: center;
`

export const FlexRowCenter = styled(FlexContainer)`
  justify-content: center;
`

export const FlexBetween = styled(FlexContainer)`
  justify-content: space-between;
`

export const FlexBetweenVerticalCenter = styled(FlexBetween)`
  align-items: center;
`

export const FlexEnd = styled(FlexContainer)`
  justify-content: flex-end;
`

export const Container = styled.div`
  padding: 1rem 2rem;
  
  ${media.small} {
    padding: .5rem 1rem;
  }
`

export const MainContainer = styled.main`
  margin: 1rem 2rem;
  flex-grow: 1;
  
  ${media.medium} {
    margin-top: -1rem;
  }
  
  ${media.small} {
    margin: 0 1rem;
  }
`

interface MediaQueryProps {
  minWidth?: number
  maxWidth?: number
}

export const MediaQuery = styled.div`
  display: none;
  ${({ minWidth, maxWidth }: MediaQueryProps) => minWidth
    ? `
    @media all and (min-width: ${minWidth}px) {
      display: block;
    }`
    : `@media all and (max-width: ${maxWidth}px) {
      display: block;
    }
  `}
`


export const LeftPanel = styled.section`
  flex: 3;
  max-width: 500px;
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  padding-right: 2rem;
  padding-top: 9rem;
  margin: -9rem 0 -1rem;
  
  ${media.medium} {
    border-right: none;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }
`

export const RightPanel = styled.aside`
  flex: 5;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 2rem;
  display: flex;
  align-self: stretch;
  
  ${media.medium} {
    padding-left: 0;
  }
`

type Panel = 'left' | 'right'

const SidesStyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  
  ${media.medium} {
    ${({ selectedPanel }: { selectedPanel: Panel }) =>
    selectedPanel === 'left'
      ? `${RightPanel} {
          display: none;
        }`
      : `${LeftPanel} {
          display: none;
        }`
}
`

interface SidesContainerProps {
  children: ReactNode
  leftPanelTitle: string
  rightPanelTitle: string
}


const PanelSwitch = styled(FlexBetween)`
   box-shadow: ${({ theme }) => `0 8px 10px ${theme.colors.gray4}`};
   margin: 0 -2rem 1rem;
   display: none;

   ${media.medium} {
    display: flex;
   }
   
   ${media.small} {
    margin-left: -1rem;
    margin-right: -1rem;
   }
`

interface PanelSwitchItemProps {
  selected: boolean
  theme: Theme
}

const PanelSwitchItem = styled.div`
  text-align: center;
  flex: 1;
  padding: 1.5rem 0;
  border-bottom: ${({ selected, theme }: PanelSwitchItemProps) => selected ? `3px solid ${theme.colors.primary}` : ''};
  ${BoldText} {
    font-size: ${({ theme }) => theme.sizes.s}rem;
  }
  
  ${media.medium} {
    padding: .7rem 0;
  }
`

export const Block = styled.div`
  margin: 1rem 0;
`

const PanelTitle = styled(BoldText)`
  cursor: pointer;
`

export const PanelContainer = (props: SidesContainerProps) => {
  const [selectedPanel, selectPanel] = useState<Panel>('left')
  return (
    <>
      <PanelSwitch>
        <PanelSwitchItem
          selected={selectedPanel === 'left'}
          onClick={useMemo(() => () => selectPanel('left'), [])}
        >
          <PanelTitle>{props.leftPanelTitle}</PanelTitle>
        </PanelSwitchItem>
        <PanelSwitchItem
          selected={selectedPanel === 'right'}
          onClick={useMemo(() => () => selectPanel('right'), [])}
        >
          <PanelTitle>{props.rightPanelTitle}</PanelTitle>
        </PanelSwitchItem>
      </PanelSwitch>
      <SidesStyledContainer selectedPanel={selectedPanel}>
        {props.children}
      </SidesStyledContainer>
    </>
  )
}
