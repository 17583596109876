import React from 'react'
import styled from 'styled-components'
import { SelectProps } from './_common'
import caret from '@ui/assets/down-caret.svg'

const SelectContainer = styled.label`
  position: relative;
  display: inline-block;
  margin: .5rem;
  background: url(${caret}) no-repeat right center;
  background-size: .5rem;
`

export const SelectElement = styled.select`
  appearance: none;
  outline: none;
  color: ${({ theme }) => theme.colors.gray1};
  border: none;
  background: none;
  font-size: ${({ theme }) => theme.sizes.s}rem;
  padding: .2rem 1rem .2rem .5rem;
`

export const Select = (props: SelectProps) => (
  <SelectContainer>
    <SelectElement
      name={props.name}
      onChange={evt => props.onSelect(evt.target.value)}
      value={props.selectedOption}
    >
      {props.values.map(value =>
        <option key={value.id} value={value.id} disabled={value.disabled}>{value.name}</option>)}
    </SelectElement>
  </SelectContainer>
)
