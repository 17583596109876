import React from 'react'
import styled from 'styled-components'

interface Props {
  min?: number
  max: number
  step?: number
  value: number
  onChange: (newValue: number) => void
  disabled: boolean
}

const StyledSlider = styled.input`
  appearance: none;
  height: 2px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.gray1};
  outline: none;
  transition: opacity .2s;
  box-sizing: border-box;
  margin: 1rem .3rem;
  position: relative;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};

  &::-webkit-slider-thumb {
    appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%; 
    background: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0,0,0,.3);
  }

  &::-moz-range-thumb {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0,0,0,.3);
  }
  
  &:before {
    content: '';
    height: 2px;
    background: ${({ theme }) => theme.colors.primary};
    width: ${({ value, min, max }: { value: number, min: number, max: number }) => ((value - min) * 100) / (max - min)}%;
    display: block;
    position: absolute;
  }
`

export const Slider = (props: Props) => (
  <StyledSlider
    type="range"
    disabled={props.disabled}
    min={props.min || 0}
    max={props.max}
    step={props.step || 1}
    value={props.value > props.max ? props.max : props.value}
    onChange={evt => props.onChange(Number(evt.target.value))}
  />
)
