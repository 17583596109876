import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { FlexBetween, FlexColumn } from '@ui/layout'
import { SubTitle } from '@ui/text'
import Assets from '@ui/assets'
import { media } from '@ui/_common'
import { noScrollEnabled } from 'utils/dom'

interface Props {
  title?: string
  opened: boolean
  onClose: () => void
  children: React.ReactNode
  fullWidth?: boolean
}

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  
  ${media.small} {
    padding: 1rem;
  }
`

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 1.2rem 1rem;
  min-width: 350px;
  ${({ fullWidth }: {fullWidth?: boolean}) => {
    if (fullWidth) {
      return 'width: 100%'
    }
    return 'max-width: 600px'
  }};
  min-height: 120px;
  max-height: 90vh;
  overflow: auto;
  
  ${media.small} {
    width: 90%;
    min-width: auto;
    max-height: 80vh;
  }
`

const Header = styled(FlexBetween)`
  margin-bottom: .8rem;
  align-items: center;
  
  span {
    cursor: pointer;
  }
`

export const PopupText = styled.p`
  line-height: 1.3rem;
  font-size: ${({ theme }) => theme.sizes.s}rem;
`

export const Popup = (props: Props) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (props.opened) {
      const handler = (evt: KeyboardEvent) => {
        if (evt.key === 'Escape') {
          props.onClose()
        }
      }
      document.body.addEventListener('keydown', handler)
      noScrollEnabled(true)
      return () => {
        document.body.removeEventListener('keydown', handler)
        noScrollEnabled(false)
      }
    }
  })

  if (!props.opened) {
    return null
  }

  return createPortal(
    <PopupContainer>
      <Content fullWidth={props.fullWidth}>
        <FlexColumn>
          <Header>
            <SubTitle>{props.title}</SubTitle>
            <Assets.CloseIcon onClick={props.onClose}/>
          </Header>
          {props.children}
        </FlexColumn>
      </Content>
    </PopupContainer>,
    document.getElementById('modal-root')!,
  )
}
