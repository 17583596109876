import React, { useContext, useState } from 'react'
import errorContext from '../../../contexts/error'
import { SuccessMessage } from '../../../@ui/text'
import { __ } from '../../../services/locale'
import localeContext from '../../../contexts/locale'
import { Button } from '@ui/buttons'
import { Input } from '@ui/input'
import { Popup, PopupText } from '@ui/popup'
import api from 'services/api'

interface Props {
  open: boolean
  onClose: () => void
  title: string
  text: string
}

const PasswordChangePopup = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [msg, setMessage] = useState('')
  const [changed, setChanged] = useState(false)
  const errorCtx = useContext(errorContext)
  const localeCtx = useContext(localeContext)

  const handleClose = () => {
    setChanged(false)
    setOldPassword('')
    setNewPassword('')
    setMessage('')
    errorCtx.setErrorMessage('')
    props.onClose()
  }

  return (
    <Popup
      title={props.title}
      opened={props.open}
      onClose={handleClose}
    >
      <PopupText>
        {msg && <SuccessMessage>{msg}</SuccessMessage>}
      </PopupText>
      {!changed && (
        <>
          <Input
            type="password"
            placeholder={__('profile.password-change.old-password', localeCtx.lang)}
            value={oldPassword}
            onChange={evt => setOldPassword(evt.target.value)}
          />
          <Input
            type="password"
            placeholder={__('profile.password-change.new-password', localeCtx.lang)}
            value={newPassword}
            onChange={evt => setNewPassword(evt.target.value)}
          />
        </>
      )}
      {changed ? (
        <Button onClick={handleClose}>
          {__('general.close', localeCtx.lang)}
        </Button>
      ) : (
        <Button
          loading={loading}
          onClick={async () => {
            if (oldPassword.trim() && newPassword.trim()) {
              try {
                setLoading(true)
                errorCtx.setErrorMessage('')
                setMessage('')
                await api.changePassword(oldPassword, newPassword)
                setMessage(__('profile.password-change.success', localeCtx.lang))
                setChanged(true)
              } catch (err) {
                if (err.response && err.response.data) {
                  if (err.response.data.error === 'wrong input') {
                    errorCtx.setErrorMessage(__('profile.password-change.err-short', localeCtx.lang))
                  } else if (err.response.data.error === 'not allowed') {
                    errorCtx.setErrorMessage(__('profile.password-change.err-incorrect', localeCtx.lang))
                  } else {
                    errorCtx.setErrorMessage(__('profile.password-change.err-generic', localeCtx.lang))
                  }
                } else {
                  errorCtx.setErrorMessage(__('profile.password-change.err-generic', localeCtx.lang))
                }
              } finally {
                setLoading(false)
              }
            }
          }}
        >
          {__('profile.password-change.submit', localeCtx.lang)}
        </Button>
      )}
    </Popup>
  )
}

export default PasswordChangePopup
