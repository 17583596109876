// example of input -> mode-0:8:0-0:16:0 mode-3:19:30-4:2:0 mode-6:6:0-6:22:0
import { SchedulePlan, SchedulePlanRange } from 'services/api/responses'

export const ScheduleModeDnd = 'drag-n-drop'

// 48x 30 minute intervals => 1 day
export const DAY_TIME_UNITS = 24 * 2

const getRangeIndexes = (planRange: SchedulePlanRange) => [
  (planRange.dayFrom * DAY_TIME_UNITS) + planRange.timeUnitFrom,
  (planRange.dayTo * DAY_TIME_UNITS) + planRange.timeUnitTo,
]

export const convertDayUnitsToTime = (dayUnits: number): Time => {
  if (dayUnits % 2 !== 0) {
    return {
      hours: Math.floor(dayUnits / 2),
      minutes: 30,
    }
  }
  return {
    hours: dayUnits / 2,
    minutes: 0,
  }
}

export const convertTimeToDayUnits = (time: Time) => {
  if (time.minutes !== 0) {
    return (time.hours * 2) + 1
  }
  return time.hours * 2
}

export interface Time {
  hours: number
  minutes: number
}

export const addPlanRange = (
  planRange: SchedulePlanRange,
  plan: SchedulePlan,
): SchedulePlan => {
  const [indexFrom, indexTo] = getRangeIndexes(planRange)
  return plan.map(({ mode, enabled }, index) => {
    // skip other plans, just return original value
    if (index < indexFrom || (index >= indexTo && indexTo !== 0)) { // indexTo is 0 when the plan is until the end of the day
      return {
        mode,
        enabled,
      }
    }
    // now we are in a new plan index range
    if (enabled && mode !== ScheduleModeDnd) {
      throw new PlansOverlapError()
    }
    return {
      enabled: true,
      mode: planRange.mode!,
    }
  })
}

export class PlansOverlapError extends Error {}

export const deletePlanRange = (planRange: SchedulePlanRange, plan: SchedulePlan): SchedulePlan => {
  const [indexFrom, indexTo] = getRangeIndexes(planRange)
  return plan.map(({ enabled, mode }, index) => {
    const shouldDelete = index >= indexFrom && index < indexTo
    return {
      enabled: shouldDelete ? false : enabled,
      mode: shouldDelete ? '' : mode,
    }
  })
}

export const parsePlan = (plan: string): SchedulePlan => {
  // monday to sunday, 24 ( hours ) * 2 ( 30 minute intervals ) * (days)
  let table = new Array(DAY_TIME_UNITS * 7).fill({ enabled: false, mode: '' })

  if (!plan.length) {
    return table
  }

  const entries = plan.trimStart().trimEnd().split(' ')
  entries.forEach(scheduledRange => {
    const [mode, from, to] = scheduledRange.split('-')
    const [dayFrom, hourFrom, minuteFrom] = from.split(':').map(Number)
    const [dayTo, hourTo, minuteTo] = to.split(':').map(Number)
    table = addPlanRange({
      mode,
      dayFrom,
      timeUnitFrom: (hourFrom * 2) + (minuteFrom !== 0 ? 1 : 0),
      dayTo,
      timeUnitTo: (hourTo * 2) + (minuteTo !== 0 ? 1 : 0),
    }, table)
  })
  return table
}

export const remapPlanToRow = (plan: SchedulePlan) => {
  const row = []
  let colspan = 0
  let colspanMode = ''
  let timeUnitFrom = -1
  let timeUnitTo = -1

  for (let index = 0; index < plan.length; index += 1) {
    if (colspanMode === '') {
      colspanMode = plan[index].mode
    }

    if (plan[index].enabled && plan[index].mode === colspanMode) {
      colspan += 1
      if (timeUnitFrom === -1) {
        timeUnitFrom = index
      }
      continue
    }
    if (colspan >= 1) {
      timeUnitTo = index
      // add colspan item
      row.push({
        enabled: true,
        colspan,
        timeUnitFrom,
        timeUnitTo,
        mode: colspanMode,
      })
      colspan = 0
      colspanMode = ''
      timeUnitFrom = -1
      timeUnitTo = -1
    }
    // add normal item
    if (plan[index].mode === '') {
      row.push({
        enabled: false,
        colspan: 1,
        timeUnit: index,
        mode: '',
      })
    } else {
      // if next item is already beginning of new mode plan, start counting right away
      colspan += 1
      colspanMode = plan[index].mode
    }
  }
  if (colspan >= 1) {
    row.push({
      enabled: true,
      colspan,
      timeUnitFrom,
      timeUnitTo: DAY_TIME_UNITS,
      mode: colspanMode,
    })
  }
  return row
}

const resetRange = () => ({
  dayFrom: -1,
  timeUnitFrom: -1,
  dayTo: -1,
  timeUnitTo: -1,
})

export const removeDnDPlan = (plan: SchedulePlan) =>
  plan.map(({ mode, enabled }) => {
    if (mode === ScheduleModeDnd) {
      return {
        enabled: false,
        mode: '',
      }
    }
    return {
      enabled,
      mode,
    }
  })

export const convertPlanToString = (plan: SchedulePlan) => {
  let planString = ''
  let range: SchedulePlanRange = resetRange()
  let previousHourEnabled = plan[0].enabled
  let previousHourMode = plan[0].mode

  for (let index = 0; index < plan.length; index++) {
    const day = Math.floor(index / DAY_TIME_UNITS)
    const timeUnit = index < DAY_TIME_UNITS ? index : index % DAY_TIME_UNITS
    const timeUnitEnabled = plan[index].enabled

    // start range if timeUnit is enabled and it wasn't assigned yet
    if (timeUnitEnabled && range.dayFrom === -1) {
      range.dayFrom = day
      range.timeUnitFrom = timeUnit
    }

    // close range if timeUnit is not enabled but previous timeUnit was enabled
    if ((!timeUnitEnabled || (index + 1) === plan.length || plan[index].mode !== previousHourMode) && previousHourEnabled) {
      range.dayTo = day
      range.timeUnitTo = timeUnit
      const timeFrom = convertDayUnitsToTime(range.timeUnitFrom)
      const timeTo = convertDayUnitsToTime(range.timeUnitTo)
      const planFromString = `${range.dayFrom}:${timeFrom.hours}:${timeFrom.minutes}`
      const planToString = `${range.dayTo}:${timeTo.hours}:${timeTo.minutes}`
      planString += `${previousHourMode}-${planFromString}-${planToString} `
      range = resetRange()
      if (plan[index].mode !== '') {
        range.dayFrom = day
        range.timeUnitFrom = timeUnit
      }
    }
    previousHourEnabled = timeUnitEnabled
    previousHourMode = plan[index].mode
  }

  // if there is not finished range, it means that it goes until end of the week (planTo is the beginning of the new week - 0:0:0)
  if (range.dayFrom !== -1) {
    const timeFrom = convertDayUnitsToTime(range.timeUnitFrom)
    const planFromString = `${range.dayFrom}:${timeFrom.hours}:${timeFrom.minutes}`
    const planToString = '0:0:0'
    planString += `${previousHourMode}-${planFromString}-${planToString}`
  }
  return planString.trimStart().trimEnd()
}

export const getPlanDayTo = (timeTo: number, dayIndex: number) => timeTo < DAY_TIME_UNITS - 1 ? dayIndex : dayIndex + 1

export const getPlanTimeUnitTo = (timeTo: number) => timeTo < DAY_TIME_UNITS - 1 ? timeTo + 1 : 0

export const getPlanFromDnD = ({
  dayIndex, dragStart, timeUnit,
}: { dayIndex: number, dragStart: {dayFrom: number, timeUnitFrom: number}, timeUnit: number }) => {
  const plan: SchedulePlanRange = {
    mode: ScheduleModeDnd,
    dayFrom: -1,
    dayTo: -1,
    timeUnitFrom: -1,
    timeUnitTo: -1,
  }
  if (dayIndex === dragStart.dayFrom) {
    plan.dayFrom = dragStart.dayFrom
    plan.dayTo = dayIndex
    if (dragStart.timeUnitFrom <= timeUnit!) {
      plan.timeUnitFrom = dragStart.timeUnitFrom
      plan.timeUnitTo = getPlanTimeUnitTo(timeUnit!)
      plan.dayTo = getPlanDayTo(timeUnit!, dayIndex)
    } else {
      plan.timeUnitFrom = timeUnit!
      plan.timeUnitTo = getPlanTimeUnitTo(dragStart.timeUnitFrom)
      plan.dayTo = getPlanDayTo(dragStart.timeUnitFrom, dayIndex)
    }
    return plan
  } else if (dayIndex < dragStart.dayFrom) {
    plan.dayFrom = dayIndex
    plan.dayTo = getPlanDayTo(dragStart.timeUnitFrom, dragStart.dayFrom)
    plan.timeUnitFrom = timeUnit!
    plan.timeUnitTo = getPlanTimeUnitTo(dragStart.timeUnitFrom)
    return plan
  }
  plan.dayFrom = dragStart.dayFrom
  plan.timeUnitFrom = dragStart.timeUnitFrom
  plan.dayTo = getPlanDayTo(timeUnit!, dayIndex)
  plan.timeUnitTo = getPlanTimeUnitTo(timeUnit!)
  return plan
}

export const remapPlanToVerticalRows = (plan: SchedulePlan) => {
  const rows = []
  for (let timeUnit = 0; timeUnit < DAY_TIME_UNITS; timeUnit += 1) {
    const timeUnitPlan = []
    for (let dayIndex = 0; dayIndex < 7; dayIndex += 1) {
      const dayTimeUnitIndex = (dayIndex * DAY_TIME_UNITS) + timeUnit
      timeUnitPlan.push({
        enabled: plan[dayTimeUnitIndex].enabled,
        mode: plan[dayTimeUnitIndex].mode,
      })
    }
    rows.push(timeUnitPlan)
  }
  return rows
}
