import React, { useLayoutEffect, useRef, useState } from 'react'
import house from '@ui/assets/house.svg'
import { HouseWrapper } from 'components/Dashboard'

interface Props {
  humidity: number|string|null
  indoorTemperature: number|string
  outdoorTemperature: number|string
  inhale: number|string
  exhale: number|string
}

const getValueOrDefault = (val: string|number|null) => typeof val !== 'undefined' && val !== null ? val : '-'

const House = (props: Props) => {
  const houseRef = useRef<HTMLObjectElement>(null)

  // hack to rerender component once SVG content is loaded
  // so we can access its DOM
  const [component, rerender] = useState()

  useLayoutEffect(() => {
    if (houseRef.current && houseRef.current.contentDocument) {
      const humidity = houseRef.current.contentDocument.getElementById('humidity')
      const humidityWrapper = houseRef.current.contentDocument.getElementById('humidity-wrapper')
      const indoorTemperature = houseRef.current.contentDocument.getElementById('indoorTemperature')
      const outdoorTemperature = houseRef.current.contentDocument.getElementById('outdoorTemperature')
      const inhale = houseRef.current.contentDocument.getElementById('inhale')
      const exhale = houseRef.current.contentDocument.getElementById('exhale')
      if (humidityWrapper) {
        if (props.humidity === null || props.humidity === 0) {
          humidityWrapper.style.display = 'none'
        } else {
          humidityWrapper.style.display = 'block'
        }
      }
      if (humidity) {
        humidity.textContent = `${getValueOrDefault(props.humidity)} %`
      }
      if (indoorTemperature) {
        indoorTemperature.textContent = `${getValueOrDefault(props.indoorTemperature)} °C`
      }
      if (outdoorTemperature) {
        outdoorTemperature.textContent = `${getValueOrDefault(props.outdoorTemperature)} °C`
      }
      if (inhale) {
        inhale.textContent = `${getValueOrDefault(props.inhale)} °C`
      }
      if (exhale) {
        exhale.textContent = `${getValueOrDefault(props.exhale)} °C`
      }
    }
  }, [props, component])

  return <HouseWrapper data={house} id="house" type="image/svg+xml" ref={houseRef} onLoad={rerender}/>
}

export default House
