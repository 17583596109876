import React, { useContext, useEffect, useState } from 'react'
import { withTheme } from 'styled-components'
import { Card } from '@ui/card'
import { BoldText } from '@ui/text'
import Assets from '@ui/assets'
import { ButtonLink } from '@ui/buttons'
import api from 'services/api'
import { __ } from 'services/locale'
import { CardRow, CardTitle } from 'components/Notifications'
import localeContext from 'contexts/locale'
import { Theme } from '@ui'
import { ApiInfoResponse } from 'services/api/responses'
import Loader from 'components/Loader'
import dataContext from 'contexts/data'
import { CardsContainer, ButtonsContainer } from 'components/Profile'
import PasswordChange from 'containers/Profile/PasswordChange'
import ResetFilters from 'containers/Profile/ResetFilters'

const getManualUrl = (lang: string, unitModel: string) => {
  const unitModelPrefix = unitModel.toLowerCase().substr(0, 5)
  return `/resources/manual-${unitModelPrefix}-${lang}.pdf`
}

const Profile = ({ theme }: { theme: Theme }) => {
  const localeCtx = useContext(localeContext)
  const dataCtx = useContext(dataContext)
  const [loading, setLoading] = useState(true)
  const [unitData, setUnitData] = useState<ApiInfoResponse>({
    unit: {
      type: '-',
      model: '-',
      sn: '-',
    },
    contacts: {
      service: {
        name: '-',
        phone: '-',
        web: '-',
        mail: '-',
      },
    },
  })
  const [manualUrl, setManualUrl] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const unitInfo = await api.getUnitInfo()
      setUnitData(unitInfo)
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    dataCtx.set({ ...dataCtx.data, title: __('navigation.profile', localeCtx.lang) })
    setManualUrl(getManualUrl(localeCtx.lang, unitData.unit.model))
  }, [localeCtx, unitData]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loader/>
  }

  return (
    <>
      <CardsContainer>
        <Card color={theme.colors.primary}>
          <CardTitle>{__('profile.subtitles.unit', localeCtx.lang)}</CardTitle>
          <CardRow>
            <BoldText>{__('profile.type', localeCtx.lang)}</BoldText>
            <BoldText>{unitData.unit.type}</BoldText>
          </CardRow>
          <CardRow>
            <BoldText>{__('profile.model', localeCtx.lang)}</BoldText>
            <BoldText>{unitData.unit.model}</BoldText>
          </CardRow>
          <CardRow>
            <BoldText>{__('profile.serial-number', localeCtx.lang)}</BoldText>
            <BoldText>{unitData.unit.sn}</BoldText>
          </CardRow>
          <CardRow>
            <ButtonLink href={manualUrl} target="_blank">
              <Assets.ManualIcon/>
              {__('profile.subtitles.tutorial', localeCtx.lang)}
            </ButtonLink>
          </CardRow>
        </Card>
        <Card color={theme.colors.primary}>
          <CardTitle>{__('profile.subtitles.contact', localeCtx.lang)}</CardTitle>
          <CardRow>
            <BoldText>{__('general.name', localeCtx.lang)}</BoldText>
            <BoldText>{unitData.contacts.service.name}</BoldText>
          </CardRow>
          <CardRow>
            <BoldText>{__('general.web', localeCtx.lang)}</BoldText>
            <BoldText>
              <a href={unitData.contacts.service.web}>{unitData.contacts.service.web}</a>
            </BoldText>
          </CardRow>
          <CardRow>
            <BoldText>{__('general.email', localeCtx.lang)}</BoldText>
            <BoldText>
              <a href={`mailto:${unitData.contacts.service.mail}`}>{unitData.contacts.service.mail}</a>
            </BoldText>
          </CardRow>
        </Card>
      </CardsContainer>
      <ButtonsContainer>
        <PasswordChange/>
        <ResetFilters/>
      </ButtonsContainer>
    </>
  )
}

export default withTheme(Profile)
