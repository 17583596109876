/* eslint-disable max-len, no-inline-comments */

interface Config {
  env: string
  app: {
    name: string
    gitSha: string
  }
  api: {
    url: string
    timeout: number
  }
  ipApiUrl: string
  sentry: {
    dsn: string
  }
  days: {
    short: string[]
    full: string[]
  }
  hours: string[]
  locale: {
    default: string
    available: string[]
    countryToLocaleMap: {[key: string]: string}
  }
  defaultPoolRefresh: number
  boostDurationAlertThreshold: number
  unitAwaiterTimeout: number
  notifications: {
    maxLength: number
  }
}

const config: Config = {
  env: process.env.REACT_APP_ENV as string,
  app: {
    name: 'wafe-dashboard',
    gitSha: process.env.REACT_APP_COMMIT_SHA || 'unknown',
  },
  api: {
    url: process.env.REACT_APP_API_URL as string,
    timeout: 3000,
  },
  ipApiUrl: 'https://go2my.wafe.eu/ip-geolocate',
  sentry: {
    dsn: 'https://2d43f4aa9fed4e3bb6426a4f6f065609@sentry.io/1487421',
  },
  days: {
    short: ['days.short.mo', 'days.short.tu', 'days.short.we', 'days.short.th', 'days.short.fr', 'days.short.sa', 'days.short.su'],
    full: ['days.long.mo', 'days.long.tu', 'days.long.we', 'days.long.th', 'days.long.fr', 'days.long.sa', 'days.long.su'],
  },
  hours: [
    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', // eslint-disable-line array-element-newline
    '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', // eslint-disable-line array-element-newline
  ],
  locale: {
    default: 'en',
    available: ['cz', 'en'],
    countryToLocaleMap: {
      CZ: 'cz',
      SK: 'cz',
      // if the country is not specified in this map, the default locale (en) will be used
    },
  },
  defaultPoolRefresh: 5000, // 5 seconds
  boostDurationAlertThreshold: 2 * 60 * 60,
  unitAwaiterTimeout: 45, // if value doesn't change within this time amount, it will throw an error
  notifications: {
    maxLength: 150,
  },
}

export default config
