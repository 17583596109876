/* eslint-disable @typescript-eslint/no-explicit-any */

type ObjectType = any

export const getObjVal = (object: {[x: string]: ObjectType}, dotPath: string): ObjectType =>
  dotPath.split('.').reduce((obj, index) => obj[index], object)

export type AnyObject = {[key: string]: any}

export const isEmpty = (value: ObjectType) => {
  if (!value) {
    return true
  }
  return Object.entries(value).length === 0 && value.constructor === Object
}
