import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Theme } from '@ui'
import { boxShadow, media } from '@ui/_common'
import { notificationColor } from 'containers/Notifications/utils'
import { NotificationType } from 'services/api/responses'

interface Props {
  to: string
  active?: boolean
  children: React.ReactNode
  onClick?: () => void
}

export const NavListItem = styled.li`
  display: inline-block;
  padding: 0 .5rem;
  cursor: pointer;
  white-space: nowrap;
  
  &, &:hover,
  a, a:hover, a:active, a:visited, a:focus {
    color: ${({ theme, active }: { theme: Theme, active?: boolean }) => active ? theme.colors.black : theme.colors.gray1};
    text-decoration: none;
    transition: color 100ms linear;
    font-weight: ${({ active }: { active?: boolean }) => active ? 600 : 400};
    font-family: ${({ active }: { active?: boolean }) => active ? 'OpenSansSemibold' : 'OpenSans'}, sans-serif;
    font-size: ${({ theme }) => theme.sizes.s}rem;
    
    ${media.medium} {
      font-size: ${({ theme }) => theme.sizes.l}rem;
    }
    
    &:hover {
      color: ${({ theme, active }: { theme: Theme, active?: boolean }) => !active && theme.colors.gray2};
    }
  }
  
  ${media.medium} {
    padding: .5rem;
  }
`

export const Nav = styled.ul`
  list-style: none;
  text-align: right;
  display: flex;
  align-items: flex-start;
  margin: .5rem 0;
  
  ${media.medium} {
    flex-direction: column;
    align-items: center;
  }
`

const DropdownContainer = styled.ul`
  display: inline;
  position: relative;
`

const DropdownMenu = styled.ul`
  position: absolute;
  background: white;
  right: 0;
  ${({ theme }: {theme: Theme}) => boxShadow(theme)};
  ${NavListItem} {
    display: block;
    text-align: left;
  }
  
  ${media.medium} {
    box-shadow: none;
    position: static;
  }
`

const DropdownTitle = styled(NavListItem)`
  ${({ isOpen, theme }: {isOpen: boolean, theme: Theme}) => isOpen && `
    ${boxShadow(theme)}
  `}
  ${media.medium} {
    box-shadow: none;
  }
`

export const NavItem = (props: Props) => (
  <NavListItem active={props.active} onClick={props.onClick}>
    <Link to={props.to}>{props.children}</Link>
  </NavListItem>
)

export const DropdownNav = (props: { title: string, children: React.ReactNode }) => {
  const [isOpen, openMenu] = useState(false)

  return (
    <DropdownContainer>
      <DropdownTitle isOpen={isOpen} onClick={() => openMenu(!isOpen)}>{props.title}</DropdownTitle>
      {isOpen && (
        <DropdownMenu>
          {isOpen && props.children}
        </DropdownMenu>
      )
      }
    </DropdownContainer>

  )
}

export const NotificationIcon = styled.span`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    left: -10px;
    border-radius: 50%;
    top: 7px;
    background: ${({ type, theme }: {type: NotificationType, theme: Theme}) => notificationColor(type, theme)};
    
    ${media.medium} {
      top: .65rem;
    }
  }
`
