import styled from 'styled-components'
import React from 'react'
import Assets from '@ui/assets'
import { FlexColumn } from '@ui/layout'

const LoaderContainer = styled(FlexColumn)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
`

const Loader = () => (
  <LoaderContainer>
    <Assets.Logo/>
    <Assets.Loader/>
  </LoaderContainer>
)

export default Loader
