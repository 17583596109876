/* eslint-disable max-classes-per-file */
// This service keeps track of changes that were sent to the API but it takes some time to update the unit with new data
// This allows to store value at the time of firing an API request and check
// if the new value from API is matching desired(locally set) value
// It waits for predefined time and if the new value from API still doesn't match desired value, it throws an error

import isEqual from 'lodash.isequal'
import config from 'config'
import { AnyObject } from 'utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Value = any

type RegisteredValues = {
  [key: string]: {
    value: Value
    registeredAt: Date
  }
}

class ValueTimeoutError extends Error {
  public context: AnyObject

  public constructor(context: AnyObject) {
    super('ValueTimeoutError')
    this.context = context
  }
}

class UnitAwaiter {
  private readonly registeredValues: RegisteredValues
  private readonly timeoutMs: number

  public constructor(timeoutSeconds: number) {
    this.registeredValues = {}
    this.timeoutMs = timeoutSeconds * 1000
  }

  public register(key: string, value: Value) {
    console.log(`registering value "${key}"`, value)
    this.registeredValues[key] = {
      value,
      registeredAt: new Date(),
    }
  }

  public check(key: string, currentValue: Value): boolean {
    if (!this.registeredValues[key]) {
      return true
    }
    console.log(`[${key}]`, { currentApiValue: currentValue, expectedValue: this.registeredValues[key].value })

    if (isEqual(this.registeredValues[key].value, currentValue)) {
      delete this.registeredValues[key]
      return true
    }

    const currentTimeStamp = new Date().getTime()
    const timestampOfValue = this.registeredValues[key].registeredAt.getTime()
    // if allowed time passed throw an error
    if (currentTimeStamp > timestampOfValue + this.timeoutMs) {
      const err = new ValueTimeoutError({
        key,
        ...this.registeredValues[key],
        timeoutMs: this.timeoutMs,
      })
      delete this.registeredValues[key]
      throw err
    }

    return false
  }
}

export default new UnitAwaiter(config.unitAwaiterTimeout)
