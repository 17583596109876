import React, { useContext } from 'react'
import styled from 'styled-components'
import { Theme } from '@ui'
import { BoldText, SecondarySpan, SubTitle } from '@ui/text'
import { FlexBetween } from '@ui/layout'
import Assets from '@ui/assets'
import { media } from '@ui/_common'
import { NotificationType } from 'services/api/responses'
import { teaser } from 'utils/string'
import config from 'config'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import { notificationIcon } from 'containers/Notifications/utils'

interface Props {
  title: string
  text: string
  type: NotificationType
  date: string
  unread: boolean
  onDelete: () => void
  showFull: () => void
  markAsRead: () => Promise<void>
}

const CloseContainer = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  
  ${Assets.CloseIcon} {
    width: 15px;
    height: 15px;
    background-size: 15px;
  }
`

const NotificationItemContainer = styled.div`
  padding: .5rem .5rem .5rem 2rem;
  margin: .5rem 1rem .5rem 0;
  position: relative;
  
  &:first-of-type {
    margin-top: 0;
  }
  
  ${({ type, unread, theme }: {type: NotificationType, unread: boolean, theme: Theme}) => `
    ${unread && `
      background-color: ${theme.colors.gray5};
      border-radius: 10px;
      & > * {
        color: black !important;
        font-weight: 600 !important;
      }
    `};
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 8px;
      top: .6rem;
      width: 15px;
      height: 15px;
      background-image: url(${notificationIcon(type)});
      background-repeat: no-repeat;
      background-size: contain;
    }
    
    &:hover {
      ${CloseContainer} {
        display: block;
      }
    }
   `}
  
  ${media.medium} {
    margin-right: 0;
  }
`

const NotificationText = styled.div`
  margin: 0 0 .5rem 0;
  color: ${({ theme }) => theme.colors.gray2};
  line-height: 1.3rem;
  font-size: ${({ theme }) => theme.sizes.xs}rem;
`

const NotificationTime = styled(SecondarySpan)`
  font-size: .7rem;
  color: ${({ theme }) => theme.colors.gray2};
`

const ShowFullButton = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  margin-left: -.2rem;
`

export const NotificationsList = styled.div`
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  
  ${media.medium} {
    margin: 1rem 0 0;
  }
`

export const CardTitle = styled(SubTitle)`
  margin: 0 1rem;
`

export const CardRow = styled(FlexBetween)`
  margin: 1rem;
  
  ${BoldText}:nth-of-type(2) {
    text-align: right;
  }
`

export const NoNotifications = styled(SecondarySpan)`
  display: block;
  width: 100%;
  text-align: center;
`

export const NotificationItem = (props: Props) => {
  const localeCtx = useContext(localeContext)
  const shouldShowPopup = props.text.length > config.notifications.maxLength

  return (
    <NotificationItemContainer type={props.type} unread={props.unread}>
      <NotificationText>
        <FlexBetween>
          {props.title}
          <CloseContainer onClick={props.onDelete}>
            <Assets.CloseIcon/>
          </CloseContainer>
        </FlexBetween>
      </NotificationText>
      <NotificationText onClick={() => {
        if (shouldShowPopup) {
          props.showFull()
        }
        return props.markAsRead()
      }}>
        {teaser(props.text, config.notifications.maxLength)}
        {shouldShowPopup
          ? <ShowFullButton>&nbsp;{__('notifications.show-full', localeCtx.lang)}</ShowFullButton>
          : null
        }
      </NotificationText>
      <NotificationTime>{props.date}</NotificationTime>
    </NotificationItemContainer>
  )
}
