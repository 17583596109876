import warning from '@ui/assets/warning.svg'
import error from '@ui/assets/error.svg'
import email from '@ui/assets/email.svg'
import bell from '@ui/assets/bell.svg'
import { NotificationType } from 'services/api/responses'
import { Theme } from '@ui'

export const notificationIcon = (type: NotificationType) => {
  switch (type) {
    case 'warning': return warning
    case 'error': return error
    case 'mail': return email
    case 'normal':
    default:
      return bell
  }
}

export const notificationColor = (type: NotificationType, theme: Theme) => {
  switch (type) {
    case 'warning': return theme.colors.warning
    case 'error': return theme.colors.error
    case 'mail': return theme.colors.green
    case 'normal':
    default:
      return theme.colors.primary
  }
}
