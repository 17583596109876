import { convertDayUnitsToTime } from 'containers/Schedule/ScheduleCalendar/utils'

export enum Time {
  minutes = 60,
  hours = 3600,
}

const withLeadingZeros = (val: number) => val < 10 ? `0${val}` : val

export const secondsToTime = (seconds: number): { time: Time, value: number } => {
  if (seconds < 3600) {
    return {
      time: Time.minutes,
      value: Math.round(seconds / 60),
    }
  }
  return {
    time: Time.hours,
    value: Math.round(seconds / 3600),
  }
}

export const formatDate = (timestamp: number) => {
  const dateObj = new Date(timestamp * 1000)
  const date = `${dateObj.getDate()}.${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`
  const time = `${withLeadingZeros(dateObj.getHours())}:${withLeadingZeros(dateObj.getMinutes())}`
  return `${date} ${time}`
}

export const timeUnitsToTime = (timeUnits: number) => {
  const { hours, minutes } = convertDayUnitsToTime(timeUnits)
  return `${withLeadingZeros(hours)}:${withLeadingZeros(minutes)}`
}
