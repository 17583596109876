import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { Popup } from './popup'
import { Button, StyledButton } from './buttons'
import { Block, FlexBetweenVerticalCenter, FlexColumn } from '@ui/layout'
import { SecondaryButton } from '@ui/text'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'

interface Props {
  title?: string
  message: string
  opened: boolean
  onClose: () => void
  payload?: any
  onConfirm: (payload?: any) => void | Promise<void>
  onCancel?: () => void
}

const ConfirmButtons = styled(FlexBetweenVerticalCenter)`
  ${StyledButton} {
    width: auto !important;
  }
`

export const Confirm = (props: Props) => {
  const localeCtx = useContext(localeContext)

  const handleCancel = useCallback(() => {
    if (props.onCancel) {
      props.onCancel()
    }
    props.onClose()
  }, [props])

  const handleConfirm = useCallback(() => {
    props.onConfirm(props.payload)
    props.onClose()
  }, [props])

  return (
    <Popup
      onClose={handleCancel}
      opened={props.opened}
      title={props.title || __('general.confirm', localeCtx.lang)}
    >
      <FlexColumn>
        <Block>{props.message}</Block>
        <ConfirmButtons>
          <SecondaryButton onClick={handleCancel}>{__('general.cancel', localeCtx.lang)}</SecondaryButton>
          <Button onClick={handleConfirm}>{__('general.yes', localeCtx.lang)}</Button>
        </ConfirmButtons>
      </FlexColumn>
    </Popup>
  )
}
