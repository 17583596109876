/* eslint-disable consistent-return */
import wafe from '@ui/assets/logo.png'

interface NotificationParams {
  title: string
  body?: string
}

const notificationAvailable = () => 'Notification' in window

export const init = () => {
  if (!notificationAvailable()) {
    return
  }
  return Notification.requestPermission()
}

export const notify = (params: NotificationParams) => {
  if (!notificationAvailable()) {
    return
  }
  try {
    return new Notification(params.title, {
      body: params.body,
      icon: wafe,
    })
  } catch {} // eslint-disable-line no-empty
}
