import React, { useMemo } from 'react'
import styled from 'styled-components'
import { boxShadow, media, OnSelectChange, primaryHover, SelectProps, SelectValue } from './_common'
import { Theme } from '@ui'

interface ItemProps extends SelectValue {
  onSelect: OnSelectChange
  onHover?: OnSelectChange
  onHoverEnd?: () => void
  selected: boolean
  groupName: string
}

const Item = (props: ItemProps) => {
  const handler = useMemo(() => () => props.onSelect(props.id), [props])

  return (
    <ItemContainer
      selected={props.selected}
      onMouseOver={() => props.onHover && props.onHover(props.id)}
      onMouseLeave={props.onHoverEnd}
    >
      {props.name}
      <Input
        type="radio"
        name={props.groupName}
        defaultChecked={props.selected}
        onClick={handler}/>
    </ItemContainer>
  )
}

const ItemContainer = styled.label`
  flex: 1;
  text-align: center;
  display: inline-block;
  padding: .7rem 2.5rem;
  font-size: ${({ theme }) => theme.sizes.s}rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.gray1};
  background: ${({ theme }: { theme: Theme }) => theme.colors.white};

  &:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  cursor: pointer;

  ${({ theme, selected }: { theme: Theme, selected: boolean }) =>
    selected ? `
        background-color: ${theme.colors.primary};
        border-radius: 10px;
        color: ${theme.colors.white};
        border-color: transparent;
        transform: scale(1.07);
        ${boxShadow(theme)}
        ${primaryHover(theme)}
      ` : ''
}

  ${media.small} {
    padding: .7rem 1rem;
  }

  ${media.max(340)} {
    padding: .5rem;
  }
`

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.gray1};
  border-radius: 10px;
  width: 100%;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`

const Input = styled.input`
  display: none;
`

export const Switch = (props: SelectProps) =>
  props.values.length > 0 ? (
    <Container disabled={props.disabled || false}>
      {props.values.map(item => (
        <Item
          key={item.id}
          {...item}
          onSelect={props.onSelect}
          onHover={props.onHover}
          onHoverEnd={props.onHoverEnd}
          selected={item.id === props.selectedOption}
          groupName={props.name}
        />
      ))}
    </Container>
  ) : null

