/* eslint-disable @typescript-eslint/no-explicit-any */
import { darken } from 'polished'
import { Theme } from '@ui'

export const boxShadow = (theme: Theme) => `
   box-shadow: 0 5px 20px ${theme.colors.gray4};
`

export const primaryHover = (theme: Theme) => `
  transition: background-color 100ms linear;

  &:hover {
    background-color: ${darken(0.03, theme.colors.primary)};
  }
`

export type OnSelectChange = (selectedId: any) => Promise<void> | void

export interface SelectValue {
  name: string
  id: any
  disabled?: boolean
}

export interface SelectProps {
  name: string
  values: SelectValue[]
  selectedOption: any
  onSelect: OnSelectChange
  onHover?: OnSelectChange
  onHoverEnd?: () => void
  disabled?: boolean
}

export enum ScreenSize {
  XS = 370,
  S = 500,
  M = 1120,
  L = 1390,
}

export const media = {
  xsmall: `@media (max-width: ${ScreenSize.XS / 16}em)`,
  small: `@media (max-width: ${ScreenSize.S / 16}em)`,
  medium: `@media (max-width: ${ScreenSize.M / 16}em)`,
  large: `@media (max-width: ${ScreenSize.L / 16}em)`,
  max: (size: number) => `@media (max-width: ${size / 16}em)`,
}
