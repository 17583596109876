import styled from 'styled-components'
import { media } from '@ui/_common'
import { FlexBetween, FlexColumn } from '@ui/layout'
import { Card } from '@ui/card'

export const CardsContainer = styled(FlexColumn)`
  ${Card} {
    margin: 1rem 0;
  }
`

export const ButtonsContainer = styled(FlexBetween)`
  & > * {
    flex: 1;
  }
  & > :first-of-type {
    margin-right: .5rem;
  }
  & > :last-of-type {
    margin-left: .5rem;
  }

  ${media.small} {
    flex-direction: column;

    & > :first-of-type,
    & > :last-of-type {
      margin-right: 0;
      margin-left: 0;
    }
  }
`
