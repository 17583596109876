import axios, { AxiosInstance, AxiosResponse } from 'axios'
import eventBus, { EventType } from '../../event-bus'
import config from 'config'
import {
  ApiDashboardResponse, ApiInfoResponse,
  ApiNotificationsResponse,
  ApiScheduleResponse,
  ApiStatusResponse,
} from 'services/api/responses'
import { Mode } from 'services/api/inputs'
import { AnyObject } from 'utils'
import { UnauthorizedError } from 'services/api/errors/auth'

interface ApiOptions {
  timeout: number
  prefix: string
}

class Api {
  private api: AxiosInstance
  private opts: ApiOptions

  public constructor(apiUrl: string, opts: ApiOptions = { timeout: 3000, prefix: '' }) {
    this.api = axios.create({
      baseURL: apiUrl,
      withCredentials: true,
      timeout: opts.timeout,
    })
    this.opts = opts

    this.api.interceptors.response.use(
      (res: AxiosResponse) => {
        eventBus.emit(EventType.NetworkRequestSucceeded)
        return res
      },
      this.errorHandler,
    )
  }

  public getStatus(generation?: number): Promise<ApiStatusResponse> {
    return this.fetch(`/${this.opts.prefix}/header`, generation) as Promise<ApiStatusResponse>
  }

  public getUnitInfo(generation?: number): Promise<ApiInfoResponse> {
    return this.fetch(`/${this.opts.prefix}/info`, generation) as Promise<ApiInfoResponse>
  }

  public getDashboard(generation?: number): Promise<ApiDashboardResponse> {
    return this.fetch(`/${this.opts.prefix}/main`, generation) as Promise<ApiDashboardResponse>
  }

  public getNotifications(generation?: number): Promise<ApiNotificationsResponse> {
    return this.fetch(`/${this.opts.prefix}/messages`, generation) as Promise<ApiNotificationsResponse>
  }

  public deleteNotification(id: string) {
    return this.api
      .delete(`/${this.opts.prefix}/message/${id}`)
  }

  public markNotificationRead(id: string) {
    return this.api
      .put(`/${this.opts.prefix}/message/${id}/read`)
  }

  public getSchedule(): Promise<ApiScheduleResponse> {
    return this.fetch(`/${this.opts.prefix}/schedule`) as Promise<ApiScheduleResponse>
  }

  public setSchedulePlan(plan: string) {
    return this.api
      .put(`/${this.opts.prefix}/schedule/plan`, {
        value: plan,
      })
  }

  public enableMode(mode: Mode, enabled: boolean) {
    return this.api
      .put(`/${this.opts.prefix}/main/${mode}`, {
        value: enabled,
      })
  }

  public setBoost(seconds: number) {
    return this.api
      .put(`/${this.opts.prefix}/main/boost-remaining`, {
        value: seconds,
      })
  }

  public updateUnitName(name: string) {
    return this.api
      .put(`/${this.opts.prefix}/header/name`, {
        value: name,
      })
  }

  public changeMode(mode: string) {
    return this.api
      .put(`/${this.opts.prefix}/main/authority`, {
        value: mode,
      })
  }

  public setManualPower(manualPower: number) {
    return this.api
      .put(`/${this.opts.prefix}/main/flow-requested`, {
        value: manualPower,
      })
  }

  public resetFilters() {
    return this.api
      .put(`/${this.opts.prefix}/main/filters`, {
        value: 'reset',
      })
  }

  public async login(username: string, password: string) {
    await this.api
      .post('/auth/context', {
        username,
        password,
      })
  }

  public logout() {
    return this.api
      .delete('/auth/context')
  }

  public changePassword(oldPassword: string, newPassword: string) {
    return this.api
      .put('/auth/password', {
        'old-password': oldPassword,
        'new-password': newPassword,
      })
  }

  private async fetch(endpoint: string, gen?: number) {
    const params: AnyObject = {}
    if (gen) {
      params.gen = gen
    }
    return (await this.api
      .get(endpoint, {
        params,
      })).data
  }

  private errorHandler(error: any) {
    if (error.response.status === 401) {
      throw new UnauthorizedError(error.response)
    }
    throw error
  }
}

export default new Api(config.api.url, { timeout: config.api.timeout, prefix: 'api/v1' })
