import styled from 'styled-components'
import { media } from '@ui/_common'
import { FlexColumn, FlexColumnBetween, FlexEnd } from '@ui/layout'
import { Nav, NavListItem } from '@ui/nav'

export const MobileMenuSwitch = styled.div`
  display: none;
  ${media.medium} {
    display: block;
    text-align: center;
  }
`

export const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background: #fff;
  padding: 1rem;
  display: none;
  
  ${FlexColumnBetween} {
    height: 100%;
  }
  
  ${media.medium} {
    display: block;
  }
`

export const NavigationContainer = styled(FlexColumn)`
  ${media.medium} {
    ${Nav} {
      align-self: center;
      ${NavListItem} {
        &:first-of-type a {
          color: ${({ theme }) => theme.colors.black};
          display: inline-block;
          margin-bottom: 2rem;
          font-family: 'OpenSansSemibold', sans-serif;
        }
        display: block;
        text-align: center;
      }
    }
  }
  ${NavListItem}:last-of-type {
    span {
      margin-right: 0;
    }
  }
`

export const LocaleContainer = styled(FlexEnd)`
  margin-top: -1.5rem;
  ${media.medium} {
    justify-content: center;
    ${Nav} {
      flex-direction: row;
      ${NavListItem} {
        display: inline-block;
        margin: .5rem;
      }
    }
  }
`

export const DateTime = styled.div`
  margin-top: .4rem;
  text-align: left;
  font-size: .7rem;
  color: ${({ theme }) => theme.colors.gray1};
  
  ${media.medium} {
    text-align: center;
  }
`
