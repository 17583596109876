import React, { useContext, useEffect, useState } from 'react'
import Loader from 'components/Loader'
import ipApi from 'services/ip-api'
import localeContext from 'contexts/locale'
import config from 'config'
import { reportError } from 'utils/error'

interface Props {
  children: React.ReactNode
}

const Locale = ({ children }: Props): React.ReactElement => {
  const [loading, setLoading] = useState(true)
  const localeCtx = useContext(localeContext)

  useEffect(() => {
    const check = async () => {
      try {
        if (!localeCtx.lang) {
          const { countryCode } = await ipApi.check()
          const lang = config.locale.countryToLocaleMap[countryCode] || config.locale.default
          window.ga('set', 'language', lang)
          localeCtx.setLanguage(lang)
        }
      } catch (err) {
        localeCtx.setLanguage(config.locale.default)
        reportError(err)
      } finally {
        setLoading(false)
      }
    }
    check()
  }, [localeCtx])

  if (loading) {
    return <Loader/>
  }
  return <>{children}</>
}

export default Locale
