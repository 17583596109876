import { useState } from 'react'

export const useConfirm = () => {
  const [confirmOpened, openConfirm] = useState(false)
  const [payload, setPayload] = useState<any>(null)

  return {
    confirmOpened,
    openConfirm,
    confirmPayload: payload,
    setConfirmPayload: setPayload,
  }
}
