import React, { useContext } from 'react'
import { Block } from '@ui/layout'
import api from 'services/api'
import { apiErrorHandler } from 'utils/error'
import { Confirm } from '@ui/confirm'
import { useConfirm } from 'hooks/useConfirm'
import errorContext from 'contexts/error'
import authContext from 'contexts/auth'
import { ButtonLink } from '@ui/buttons'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'

const ResetFilters = () => {
  const localeCtx = useContext(localeContext)
  const { confirmOpened, openConfirm, confirmPayload, setConfirmPayload } = useConfirm()
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)

  return (
    <Block>
      <ButtonLink
        href="#"
        onClick={(evt: any) => {
          evt.preventDefault()
          setConfirmPayload(__('profile.reset-filters.title', localeCtx.lang))
          openConfirm(true)
        }}
      >
        {__('profile.reset-filters.title', localeCtx.lang)}
      </ButtonLink>
      <Confirm
        opened={confirmOpened}
        payload={confirmPayload}
        onClose={() => openConfirm(false)}
        message={__('profile.reset-filters.message', localeCtx.lang)}
        onConfirm={async () => {
          await api.resetFilters().catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
        }}
      />
    </Block>
  )
}

export default ResetFilters
