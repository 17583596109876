import React from 'react'
import styled from 'styled-components'
import { Theme } from '@ui'

interface Props {
  percentage: number
  color?: string
}

const StyledProgressBar = styled.div`
  background: ${({ theme }) => theme.colors.gray5};
  border-radius: 4px;
  div {
    height: 8px;
    border-radius: 4px;
    ${({ width, color, theme }: { width: number, color?: string, theme: Theme }) => `
      width: ${width}%;
      background-color: ${color || theme.colors.primary};
    `}
  }
`

const ProgressBar = (props: Props) => (
  <StyledProgressBar
    width={props.percentage}
    color={props.color}
    // title={`${props.percentage}%`}
  >
    <div/>
  </StyledProgressBar>
)

export default ProgressBar
