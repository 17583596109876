import React, { Fragment, useContext, useMemo, useState } from 'react'
import { getPlanDayTo, getPlanFromDnD, getPlanTimeUnitTo, remapPlanToRow } from './utils'
import Assets from '@ui/assets'
import config from 'config'
import {
  BodyCell,
  ColumnTitle,
  Container,
  HeaderCell,
  LoaderContainer,
  RowTitle, Scrollable,
  Table,
  Tbody,
  Thead,
} from 'components/Schedule'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import { chunk } from 'utils/array'
import { SchedulePlan, SchedulePlanRange } from 'services/api/responses'

interface DragStart {
  dayFrom: number
  timeUnitFrom: number
}

interface RowProps {
  dayPlan: SchedulePlan
  day: string
  dayIndex: number
  removePlan: (deleteRange: SchedulePlanRange) => void
  addPlan: (newRange: SchedulePlanRange) => void
  openPlanPopup: (popupDetails: SchedulePlanRange) => void
  drag: {
    dragStart: DragStart
    updateDragStart: (dragStart: DragStart) => void
  }
}

interface Props {
  weekPlan: SchedulePlan
  loading: boolean
  removePlan: (deleteRange: SchedulePlanRange) => void
  addPlan: (newRange: SchedulePlanRange) => void
  openPlanPopup: (popupDetails: SchedulePlanRange) => void
}

const Header = () => {
  const hours = useMemo(
    () =>
      config.hours.filter((_, i) => i % 2 === 0).map(hour => (
        <Fragment key={hour}>
          <HeaderCell><ColumnTitle>{hour}</ColumnTitle></HeaderCell>
          <HeaderCell/>
          <HeaderCell/>
          <HeaderCell/>
        </Fragment>
      ))
    , [],
  )

  return (
    <Thead>
      <tr>
        <HeaderCell/>
        {hours}
      </tr>
    </Thead>
  )
}

const ScheduleRow = (props: RowProps) => (
  <tr>
    <BodyCell><RowTitle>{props.day}</RowTitle></BodyCell>
    {
      remapPlanToRow(props.dayPlan).map(({ enabled, colspan, timeUnitFrom, timeUnitTo, timeUnit, mode }, i) => (
        <BodyCell
          key={i}
          active={enabled}
          mode={mode}
          colSpan={colspan}
          draggable={typeof timeUnitFrom === 'undefined'}
          onDragStart={evt => {
            evt.dataTransfer.setData('text/plain', 'FIREFOX-SUCKS')
            if (evt.nativeEvent && evt.nativeEvent.target) {
              const element = document.createElement('span')
              element.classList.add('drag')
              // @ts-ignore
              evt.nativeEvent.target.appendChild(element)
            }
            props.drag.updateDragStart({
              dayFrom: props.dayIndex,
              timeUnitFrom: timeUnit!,
            })
          }}
          onDragOver={evt => evt.preventDefault()}
          onDragEnter={evt => evt.preventDefault()}
          onDrop={evt => {
            if (evt.preventDefault) {
              evt.preventDefault()
            }
            if (evt.nativeEvent && evt.nativeEvent.target) {
              props.addPlan(getPlanFromDnD({
                dayIndex: props.dayIndex,
                timeUnit: timeUnit!,
                dragStart: props.drag.dragStart,
              }))
              document.querySelectorAll('.drag').forEach(el => el.remove())
            }
          }}
          onClick={() => {
            if (enabled) {
              props.removePlan({
                dayFrom: props.dayIndex,
                timeUnitFrom: timeUnitFrom!,
                dayTo: props.dayIndex,
                timeUnitTo: timeUnitTo!,
                mode,
              })
            } else {
              props.openPlanPopup({
                dayFrom: props.dayIndex,
                dayTo: getPlanDayTo(timeUnit!, props.dayIndex),
                timeUnitFrom: timeUnit!,
                timeUnitTo: getPlanTimeUnitTo(timeUnit!),
                mode: 'auto',
              })
            }
          }}
        />))
    }
  </tr>
)

const Horizontal = (props: Props) => {
  const localeCtx = useContext(localeContext)
  const [dragStart, updateDragStart] = useState({
    dayFrom: 0,
    timeUnitFrom: 0,
  })
  return (
    <Scrollable>
      <Container>
        <Table>
          <Header/>
          <Tbody>
            {chunk(props.weekPlan, 7).map((dayPlan, dayIndex) => (
              <ScheduleRow
                key={dayIndex}
                day={__(config.days.short[dayIndex], localeCtx.lang)}
                dayIndex={dayIndex}
                dayPlan={dayPlan}
                removePlan={props.removePlan}
                openPlanPopup={props.openPlanPopup}
                addPlan={props.addPlan}
                drag={{ dragStart, updateDragStart }}
              />))}
          </Tbody>
        </Table>
        <Table shadow>
          <Tbody>
            {chunk(props.weekPlan, 7).map((dayPlan, i) =>
              <tr key={i}>
                <BodyCell/>
                {dayPlan.map((_, key) => <BodyCell key={key}/>)}
              </tr>)}
          </Tbody>
        </Table>
        {props.loading && <LoaderContainer><Assets.Loader/></LoaderContainer>}
      </Container>
    </Scrollable>
  )
}

export default Horizontal
