import * as React from 'react'
import styled from 'styled-components'
import logo from './logo.png'
import logo2x from './logo@2x.png'
import home from './home.svg'
import drop from './drop.svg'
import edit from './edit.svg'
import info from './info.svg'
import sun from './sun.svg'
import plus from './plus.svg'
import loader from './loader.svg'
import closeIcon from './close.svg'
import whiteCloseIcon from './close-white.svg'
import menuIcon from './menu.svg'
import searchIcon from './search.svg'
import qualityIcon from './quality.svg'
import warningIcon from './warning.svg'
import errorIcon from './error.svg'
import indoorAirIcon from './indoor-air.svg'
import outdoorAirIcon from './outdoor-air.svg'
import airIcon from './air.svg'
import airFilterIcon from './air-filter.svg'
import logoutIcon from './logout.svg'
import manualIcon from './manual.svg'
import valveIcon from './valve.svg'
import { media } from '@ui/_common'

interface IconOptions {
  size?: number
  cursorPointer?: boolean
}

export const icon = (src: string, options?: IconOptions) => styled.span`
  display: inline-block;
  margin: .3rem;
  width: ${(options && options.size) || 30}px;
  height: ${(options && options.size) || 30}px;
  cursor: ${options && options.cursorPointer ? 'pointer' : ''};
  background: url(${src}) no-repeat center;
  background-size: contain;
`

const StyledLogo = styled.img`
  margin: 0 -0.5rem;
  height: 80px;
  
  ${media.medium} {
    height: 60px;
  }
`

const Logo = () => <StyledLogo srcSet={`${logo}, ${logo2x} 2x`} alt="wafe"/>

const HomeIcon = icon(home, { size: 20 })

const DropIcon = icon(drop, { size: 20 })

const QualityIcon = icon(qualityIcon, { size: 20 })

const AirIcon = icon(airIcon, { size: 20 })

const WarningIcon = icon(warningIcon, { size: 20 })

const AirFilterIcon = icon(airFilterIcon, { size: 20 })

const ErrorIcon = icon(errorIcon, { size: 20 })

const OutdoorAirIcon = icon(outdoorAirIcon, { size: 20 })

const IndoorAirIcon = icon(indoorAirIcon, { size: 20 })

const PlusIcon = icon(plus, { size: 25 })

const EditIcon = icon(edit, { cursorPointer: true, size: 11 })

const InfoIcon = icon(info, { size: 20, cursorPointer: true })

const SunIcon = icon(sun, { size: 20 })

const Loader = icon(loader)

const SmallLoader = icon(loader, { size: 17 })

const CloseIcon = icon(closeIcon, { size: 25 })

const CloseIconWhite = icon(whiteCloseIcon, { size: 25 })

const SearchIcon = icon(searchIcon, { size: 17 })

const Menu = icon(menuIcon, { size: 22 })

const LogoutIcon = icon(logoutIcon, { size: 20 })

const ManualIcon = icon(manualIcon, { size: 25 })

const ValveIcon = icon(valveIcon, { size: 20 })

export default {
  Logo,
  HomeIcon,
  DropIcon,
  QualityIcon,
  OutdoorAirIcon,
  IndoorAirIcon,
  EditIcon,
  InfoIcon,
  SunIcon,
  Loader,
  SmallLoader,
  CloseIcon,
  CloseIconWhite,
  SearchIcon,
  Menu,
  PlusIcon,
  WarningIcon,
  ErrorIcon,
  AirIcon,
  AirFilterIcon,
  LogoutIcon,
  ManualIcon,
  ValveIcon,
}
