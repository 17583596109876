import React, { useContext, useEffect, useState } from 'react'
import { Confirm } from '@ui/confirm'
import { useConfirm } from 'hooks/useConfirm'
import { NotificationItem, NotificationsList, NoNotifications } from 'components/Notifications'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import pool from 'services/api/pool'
import api from 'services/api'
import { ApiNotificationsResponse, ApiNotification } from 'services/api/responses'
import { isEmpty } from 'utils'
import { formatDate } from 'utils/time'
import NotificationPopup from 'containers/Notifications/Popup/Notification'
import { apiErrorHandler } from 'utils/error'
import errorContext from 'contexts/error'
import authContext from 'contexts/auth'
import dataContext from 'contexts/data'
import Loader from 'components/Loader'
import * as notifications from 'services/notifications'

const Notifications = () => {
  const localeCtx = useContext(localeContext)
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)
  const dataCtx = useContext(dataContext)

  const [loading, setLoading] = useState(true)
  const [popupOpen, openPopup] = useState(false)
  const { confirmOpened, openConfirm, confirmPayload, setConfirmPayload } = useConfirm()
  const [notificationText, setNotificationPopupText] = useState({
    title: '',
    text: '',
  })

  const [messages, setMessages] = useState<ApiNotification[]>([])

  useEffect(() => pool.repeat('notifications', async (generation: number | undefined) => {
    const res = await api
      .getNotifications(generation)
      .catch(apiErrorHandler(errorCtx, localeCtx, authCtx)) as ApiNotificationsResponse

    setLoading(false)
    if (!isEmpty(res)) {
      setMessages(res.inbox)
      return res
    }
  }), []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dataCtx.set({ ...dataCtx.data, title: __('navigation.notifications', localeCtx.lang) })
  }, [localeCtx]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    notifications.init()
  }, [])

  if (loading) {
    return <Loader/>
  }

  return (
    <>
      <NotificationsList>
        {
          messages.length
            ? messages.map((message, i) => (
              <NotificationItem
                key={i}
                date={formatDate(message.datetime)}
                title={`${message.title} (${__(`notifications.types.${message.type}`, localeCtx.lang)})`}
                text={message.text}
                type={message.type}
                unread={message.unread}
                onDelete={() => {
                  setConfirmPayload(message)
                  openConfirm(true)
                }}
                showFull={() => {
                  setNotificationPopupText({
                    title: message.title,
                    text: message.text,
                  })
                  openPopup(true)
                }}
                markAsRead={async () => {
                  await api.markNotificationRead(message.id)
                  setMessages(messages.map(msg => {
                    if (msg.id === message.id) {
                      msg.unread = false
                    }
                    return msg
                  }))

                  if (dataCtx.data.unreadNotification && message.id === dataCtx.data.unreadNotification.id) {
                    dataCtx.set({
                      ...dataCtx.data,
                      unreadNotification: null,
                    })
                  }
                }}
              />
            ))
            : <NoNotifications>{__('notifications.none', localeCtx.lang)}</NoNotifications>
        }
      </NotificationsList>
      <NotificationPopup
        open={popupOpen}
        onClose={() => openPopup(false)}
        title={notificationText.title}
        text={notificationText.text}
      />
      <Confirm
        opened={confirmOpened}
        payload={confirmPayload}
        onClose={() => openConfirm(false)}
        message={__('notifications.confirm.delete', localeCtx.lang)}
        onConfirm={async (payload: ApiNotification) => {
          await api.deleteNotification(payload.id)
          setMessages(messages.filter(message => message.id !== payload.id))
        }}
      />
    </>
  )
}

export default Notifications
