import styled from 'styled-components'
import { FlexBetween } from '@ui/layout'
import loginImg from '@ui/assets/login.jpg'
import { media } from '@ui/_common'
import { Input } from '@ui/input'
import { Nav } from '@ui/nav'

export const Container = styled(FlexBetween)`
  align-items: stretch;
  min-height: 100vh;
`

export const LoginContainer = styled.aside`
  display: flex;
  flex-direction: column;
  flex: 3;
  max-width: 500px;
  margin: 2rem 3rem;
  
  ${media.medium} {
    margin: .5rem 1rem 3rem;
    max-width: 100%;
  }
`

export const LoginImage = styled.aside`
  flex: 5;
  background-image: url(${loginImg});
  background-size: cover;
  
  ${media.medium} {
    display: none;
  }
`

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: 1rem 0 0;
  
  ${Input}:first-of-type {
    margin-top: 0;
  }
`

export const ButtonsRow = styled(FlexBetween)`
  margin: 1rem 0;
  align-items: center;
  
  ${media.small} {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0;
    
    & > * {
      margin: 1rem 0;
    }
  }
`

export const LogoContainer = styled.div`
  margin-bottom: 4rem;
  
  ${media.small} {
    margin-bottom: 2rem;
  }
`

export const ErrorMessage = styled.strong`
  color: ${({ theme }) => theme.colors.red};
  display: block;
  text-align: center;
  margin: 1rem 0 0;
`

export const LocaleContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  
  ${Nav} {
    ${media.medium} {
      flex-direction: row;
    }
  }
`
