import React, { useContext, useMemo, useState } from 'react'
import Assets from '@ui/assets'
import { SecondarySpan, SecondaryText, TitleXl } from '@ui/text'
import { Input } from '@ui/input'
import { Button } from '@ui/buttons'
import { Nav, NavListItem } from '@ui/nav'
import {
  Container,
  LoginContainer,
  LoginImage,
  LoginForm,
  ButtonsRow,
  LogoContainer,
  ErrorMessage,
  LocaleContainer,
} from 'components/Login'
import { __ } from 'services/locale'
import localeContext from 'contexts/locale'
import api from 'services/api'
import { UnauthorizedError } from 'services/api/errors/auth'
import { apiErrorHandler } from 'utils/error'
import errorContext from 'contexts/error'
import authContext from 'contexts/auth'
import config from 'config'

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [inputs, updateInputs] = useState({
    email: '',
    password: '',
  })
  const [err, setErr] = useState('')
  const localeCtx = useContext(localeContext)
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)

  const locales = useMemo(() => config.locale.available.map(lang =>
    <NavListItem
      key={lang}
      active={lang === localeCtx.lang}
      onClick={() => localeCtx.setLanguage(lang)}
    >
      {lang.toUpperCase()}
    </NavListItem>), [localeCtx])

  return (
    <Container>
      <LoginContainer>
        <LogoContainer>
          <Assets.Logo/>
        </LogoContainer>
        <TitleXl>{__('login.title', localeCtx.lang)}</TitleXl>
        <SecondaryText>
          {__('login.text', localeCtx.lang)}
        </SecondaryText>
        <LoginForm onSubmit={async evt => {
          evt.preventDefault()
          setLoading(true)
          setErr('')
          try {
            await api.login(inputs.email, inputs.password)
            authCtx.setAuthenticated(true)
          } catch (error) {
            if (error instanceof UnauthorizedError) {
              setErr(__('login.incorrect', localeCtx.lang))
            } else {
              apiErrorHandler(errorCtx, localeCtx, authCtx)(error)
            }
            setLoading(false)
          }
        }}>
          <Input
            placeholder={__('login.email', localeCtx.lang)}
            value={inputs.email}
            onChange={evt => updateInputs({ ...inputs, email: evt.target.value })}
          />
          <Input
            placeholder={__('login.password', localeCtx.lang)}
            type="password"
            value={inputs.password}
            onChange={evt => updateInputs({ ...inputs, password: evt.target.value })}
          />
          <ButtonsRow>
            <SecondarySpan>&nbsp;{/* __('login.forgotten-password', localeCtx.lang)*/}</SecondarySpan>
            <Button loading={loading}>{__('login.submit', localeCtx.lang)}</Button>
          </ButtonsRow>
        </LoginForm>
        {err && <ErrorMessage>{err}</ErrorMessage>}
        <LocaleContainer>
          <Nav>{locales}</Nav>
        </LocaleContainer>
      </LoginContainer>
      <LoginImage />
    </Container>
  )
}

export default Login
