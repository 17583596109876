import React, { useContext, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Nav, NavItem, NavListItem, NotificationIcon } from '@ui/nav'
import { FlexEnd, FlexColumnBetween, MediaQuery } from '@ui/layout'
import { ScreenSize } from '@ui/_common'
import Assets from '@ui/assets'
import LocaleContext from 'contexts/locale'
import authContext from 'contexts/auth'
import config from 'config'
import { __ } from 'services/locale'
import { usePrevious } from 'hooks/usePrevious'
import { noScrollEnabled } from 'utils/dom'
import api from 'services/api'
import { DateTime, LocaleContainer, MobileMenu, MobileMenuSwitch, NavigationContainer } from 'components/Navigation'
import dataContext from 'contexts/data'
import { formatDate } from 'utils/time'

const isActive = (route: string, router: RouteComponentProps) =>
  router.location.pathname === route

const Navigation = (router: RouteComponentProps) => {
  const localeCtx = useContext(LocaleContext)
  const authCtx = useContext(authContext)
  const dataCtx = useContext(dataContext)

  const [mobileMenuOpen, toggleMobileMenu] = useState(false)

  const locales = useMemo(() => config.locale.available.map(lang =>
    <NavListItem
      key={lang}
      active={lang === localeCtx.lang}
      onClick={() => localeCtx.setLanguage(lang)}
    >
      {lang.toUpperCase()}
    </NavListItem>), [localeCtx])

  useEffect(() => {
    noScrollEnabled(mobileMenuOpen)
  }, [mobileMenuOpen])

  const menu = useMemo(() => (
    <>
      <Nav>
        <NavItem to="/">
          {
            dataCtx.data.unit && dataCtx.data.unit.name !== '-'
              ? `${dataCtx.data.unit.name} (${dataCtx.data.unit.type})`
              : '-'
          }
          <DateTime>{formatDate(dataCtx.data.unitTimestamp)}</DateTime>
        </NavItem>
        <NavItem to="/" active={isActive('/', router)}>
          {__('navigation.dashboard', localeCtx.lang)}
        </NavItem>
        <NavItem to="/notifications" active={isActive('/notifications', router)}>
          { dataCtx.data.lastNotification && dataCtx.data.lastNotification.unread
            && <NotificationIcon type={dataCtx.data.lastNotification.type}/>
          }
          {__('navigation.notifications', localeCtx.lang)}
        </NavItem>
        <NavItem to="/profile" active={isActive('/profile', router)}>
          {__('navigation.profile', localeCtx.lang)}
        </NavItem>
        <NavItem to="#" onClick={async () => {
          toggleMobileMenu(false)
          await api.logout()
          authCtx.setAuthenticated(false)
        }}>
          {__('navigation.logout', localeCtx.lang)}
        </NavItem>
      </Nav>
      <LocaleContainer>
        <Nav>{locales}</Nav>
      </LocaleContainer>
    </>
  ), [authCtx, dataCtx.data, localeCtx.lang, locales, router])

  const prevLocation = usePrevious(router.location.pathname)
  useEffect(() => {
    if (prevLocation !== router.location.pathname) {
      toggleMobileMenu(false)
    }
  }, [router.location.pathname, prevLocation])

  return (
    <NavigationContainer>
      <MediaQuery minWidth={ScreenSize.M}>{menu}</MediaQuery>
      <MobileMenuSwitch onClick={() => toggleMobileMenu(true)}><Assets.Menu/></MobileMenuSwitch>
      { mobileMenuOpen && (
        <MobileMenu>
          <FlexColumnBetween>
            <FlexEnd><Assets.CloseIcon onClick={() => toggleMobileMenu(false)}/></FlexEnd>
            {menu}
          </FlexColumnBetween>
        </MobileMenu>
      )}
    </NavigationContainer>
  )
}

export default withRouter(Navigation)
