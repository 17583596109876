import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import Navigation from './Navigation'
import Assets from '@ui/assets'
import { FlexColumnFullHeight, LeftPanel, MainContainer, PanelContainer, RightPanel } from '@ui/layout'
import { HeaderContainer, LogoContainer } from 'components'
import localeContext from 'contexts/locale'
import errorContext from 'contexts/error'
import authContext from 'contexts/auth'
import dataContext from 'contexts/data'
import api from 'services/api'
import { apiErrorHandler } from 'utils/error'
import { ApiDashboardResponse, ApiNotification, ApiStatusResponse } from 'services/api/responses'
import { isEmpty } from 'utils'
import { __ } from 'services/locale'
import { DashboardPanelTitle, OfflineUnit } from 'components/Dashboard'
import House from 'containers/Dashboard/House'
import UnitInfo from 'containers/Dashboard/UnitInfo'
import pool from 'services/api/pool'
import Loader from 'components/Loader'
import { usePrevious } from 'hooks/usePrevious'
import { notify } from 'services/notifications'
import { Button } from '@ui/buttons'

const Header = () => (
  <HeaderContainer>
    <LogoContainer>
      <Link to="/"><Assets.Logo/></Link>
      <Navigation/>
    </LogoContainer>
  </HeaderContainer>
)

export const AppContainer = (props: { children: any }) => {
  const localeCtx = useContext(localeContext)
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)
  const dataCtx = useContext(dataContext)

  const [unit, setUnit] = useState<{
    unit: {name: string, type: string}
    unitTimestamp: number
    isOnline: boolean|null
    showTurnOnSwitch: boolean|null
    lastNotification: ApiNotification|null
  }>({
    unit: {
      name: '-',
      type: '',
    },
    lastNotification: null,
    unitTimestamp: dataCtx.data.unitTimestamp,
    isOnline: true,
    showTurnOnSwitch: null,
  })

  const [dashboard, setDashboard] = useState<ApiDashboardResponse>({
    gen: 0,
    authority: '',
    'authority-available': [''],
    capabilities: [],
    'power-current': 0,
    'power-requested': 0,
    'fireplace-active': false,
    'holiday-active': false,
    'boost-remaining': 0,
    'boost-duration': 0,
    'circulation-active': false,
    'silent-active': false,
    'stop-active': true,
    'stop-progress': 100,
    temperatures: [],
    humidity: null,
    aqi: 0,
    co2: 0,
    filters: {
      waste: {
        health: 0,
        status: '',
      },
      fresh: {
        health: 0,
        status: '',
      },
    },
    'flow-min': 0,
    'flow-max': 0,
    'flow-requested': 0,
    'flow-current': 0,
  })
  const [loading, setLoading] = useState(true)

  const previousWasteStatus = usePrevious(dashboard.filters.waste.status)
  const previousFreshStatus = usePrevious(dashboard.filters.waste.status)
  useEffect(() => {
    // filters change notifications
    if (previousWasteStatus && dashboard.filters.waste.status !== previousWasteStatus) {
      notify({
        title: `${
          __('dashboard.unitinfo.filters.notification', localeCtx.lang)
        } - ${
          __('dashboard.unitinfo.filters.waste', localeCtx.lang)}`,
      })
    }
    if (previousWasteStatus && dashboard.filters.fresh.status !== previousFreshStatus) {
      notify({
        title: `${
          __('dashboard.unitinfo.filters.notification', localeCtx.lang)
        } - ${
          __('dashboard.unitinfo.filters.fresh', localeCtx.lang)}`,
      })
    }
  }, [dashboard.filters, localeCtx.lang, previousFreshStatus, previousWasteStatus])

  useEffect(() => pool.repeat('dashboard', async (generation: number|undefined) => {
    const res = await api.getDashboard(generation).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
    if (!isEmpty(res)) {
      setDashboard(res as ApiDashboardResponse)
      setLoading(false)
    }
    return res
  }), []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => pool.repeat('status', async (generation: number|undefined) => {
    const res = await api.getStatus(generation).catch(apiErrorHandler(errorCtx, localeCtx, authCtx)) as ApiStatusResponse
    if (!isEmpty(res)) {
      setUnit({
        unit: {
          name: res.name || '',
          type: res.type,
        },
        lastNotification: res.message,
        unitTimestamp: res.timestamp,
        isOnline: res.online,
        showTurnOnSwitch: res.pm,
      })
    }
    return res
  }), []) // eslint-disable-line react-hooks/exhaustive-deps

  const previousNotification = usePrevious<ApiNotification>(unit.lastNotification)
  useEffect(() => {
    dataCtx.set({
      ...unit,
      title: dataCtx.data.title,
    })
    if (unit.lastNotification
      && unit.lastNotification!.unread
      && (previousNotification === null
      || previousNotification!.id !== unit.lastNotification!.id)) {
      notify({
        title: unit.lastNotification!.text,
      })
    }
  }, [unit]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.title = dataCtx.data.title ? `${dataCtx.data.title} | Wafe` : 'Wafe'
  }, [dataCtx.data])

  const routerLocation = useLocation()
  useEffect(() => {
    window.ga('set', 'page', routerLocation.pathname)
    window.ga('send', 'pageview')
  }, [routerLocation])

  if (loading) {
    return <Loader/>
  }

  return (
    <FlexColumnFullHeight>
      { !unit.isOnline && (
        <OfflineUnit>
          <div>
            <h1>{__('dashboard.offline.title', localeCtx.lang)}</h1>
            <p>{__('dashboard.offline.description', localeCtx.lang)}</p>
            <Button
              onClick={async () => {
                await api.logout()
                authCtx.setAuthenticated(false)
              }}
            >
              {__('navigation.logout', localeCtx.lang)}
            </Button>
          </div>
        </OfflineUnit>
      )}
      <Header/>
      <MainContainer>
        <PanelContainer
          leftPanelTitle={dataCtx.data.title}
          rightPanelTitle={__('dashboard.overview-title', localeCtx.lang)}
        >
          <LeftPanel>
            <DashboardPanelTitle>{dataCtx.data.title}</DashboardPanelTitle>
            {props.children({ dashboard, showTurnOnSwitch: unit.showTurnOnSwitch })}
          </LeftPanel>
          <RightPanel>
            <DashboardPanelTitle>{__('dashboard.overview-title', localeCtx.lang)}</DashboardPanelTitle>
            <House
              humidity={dashboard.humidity}
              indoorTemperature={dashboard.temperatures[2]}
              outdoorTemperature={dashboard.temperatures[0]}
              inhale={dashboard.temperatures[1]}
              exhale={dashboard.temperatures[3]}
            />
            <UnitInfo
              temperatures={dashboard.temperatures}
              humidity={dashboard.humidity}
              filters={dashboard.filters}
              co2={dashboard.co2}
              power={dashboard['flow-current']}
            />
          </RightPanel>
        </PanelContainer>
      </MainContainer>
    </FlexColumnFullHeight>
  )
}
