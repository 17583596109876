import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { FlexBetweenVerticalCenter } from '@ui/layout'
import Assets from '@ui/assets'
import { media } from '@ui/_common'
import eventBus, { EventType } from 'event-bus'
import errorContext from 'contexts/error'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 150;
  color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
`

const Content = styled(FlexBetweenVerticalCenter)`
  background: ${({ theme }) => theme.colors.red};
  padding: .7rem 1rem;
  width: 50%;
  font-size: ${({ theme }) => theme.sizes.s}rem;

  ${media.medium} {
    width: 90%;
    span {
      width: 40px;
      height: 40px;
    }
  }
`

const ErrorMessage = () => {
  const errorCtx = useContext(errorContext)

  useEffect(() => {
    const hideErrorMessage = () => errorCtx.setErrorMessage('')
    eventBus.on(EventType.NetworkRequestSucceeded, hideErrorMessage)

    if (errorCtx.errorMessage.msg && errorCtx.errorMessage.opts.autoHide) {
      setTimeout(() => {
        errorCtx.setErrorMessage('')
      }, 5000)
    }

    return () => eventBus.removeListener(EventType.NetworkRequestSucceeded, hideErrorMessage)
  }, [errorCtx])

  if (!errorCtx.errorMessage.msg) {
    return null
  }

  return (
    <Container>
      <Content>
        {errorCtx.errorMessage.msg}
        <Assets.CloseIconWhite onClick={() => {
          errorCtx.setErrorMessage('')
        }}/>
      </Content>
    </Container>
  )
}

export default ErrorMessage
