// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const chunk = (array: any[], chunksNumber: number) => {
  const chunks = []

  for (let chunksCreated = 0; chunksCreated < chunksNumber; chunksCreated += 1) {
    const itemsInChunk = Math.ceil(array.length / chunksNumber)
    const startIndex = chunksCreated * itemsInChunk
    const endIndex = startIndex + itemsInChunk
    chunks.push(array.slice(startIndex, endIndex))
  }
  return chunks
}
