import * as Sentry from '@sentry/browser'
import { __ } from 'services/locale'
import { ErrorContext } from 'contexts/error'
import { LocaleContext } from 'contexts/locale'
import config from 'config'
import { UnauthorizedError } from 'services/api/errors/auth'
import { AuthContext } from 'contexts/auth'

export const reportError = (err: any) => {
  if (config.env === 'production') {
    Sentry.captureException(err)
  }
}

export const apiErrorHandler = (errContext: ErrorContext, localeContext: LocaleContext, authContext: AuthContext) => (err: Error) => {
  if (err instanceof UnauthorizedError) {
    authContext.setAuthenticated(false)
    errContext.setErrorMessage(__('login.expired', localeContext.lang))
    return
  }
  reportError(err)
  console.error('API ERROR:', err)
  errContext.setErrorMessage(__('general.error', localeContext.lang), { autoHide: false })
}
