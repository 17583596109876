import React, { useContext, useRef } from 'react'
import { Popup } from '@ui/popup'
import { FlexColumn, FlexEnd } from '@ui/layout'
import { Input } from '@ui/input'
import { Button } from '@ui/buttons'
import { __ } from 'services/locale'
import localeContext from 'contexts/locale'

interface Props {
  open: boolean
  onClose: () => void
  submit: (value: string) => void
  value: string
}

const UnitNamePopup = (props: Props) => {
  const localeCtx = useContext(localeContext)
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Popup
      title={__('dashboard.unitname', localeCtx.lang)}
      opened={props.open}
      onClose={props.onClose}
    >
      <FlexColumn>
        <Input
          placeholder=""
          type="text"
          defaultValue={props.value}
          ref={inputRef}
        />
        <FlexEnd>
          <Button
            onClick={() => {
              if (inputRef.current) {
                props.submit(inputRef.current.value)
              }
            }}
          >
            {__('general.set', localeCtx.lang)}
          </Button>
        </FlexEnd>
      </FlexColumn>
    </Popup>
  )
}

export default UnitNamePopup
