import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexBetweenVerticalCenter, FlexColumn } from '@ui/layout'
import down from '@ui/assets/down-caret.svg'
import up from '@ui/assets/up-caret.svg'

// TODO: add minHoursValue, minMinutesValue, maxHoursValue, maxMinutesValue
interface Props {
  hours: number
  minutes: number
  hoursStep?: number
  minutesStep?: number
  onChange: ({ hours, minutes }: {hours: number, minutes: number}) => void
}

const Container = styled(FlexBetweenVerticalCenter)`
`

const Input = styled.input`
  width: 20px;
  border: none;
  text-align: center;
  margin: .1rem .5rem;
  font-size: ${({ theme }) => theme.sizes.s}rem;
  appearance: none;
  background: #fff;
  color: #000;
  &:focus {
    outline: none;
  }
`

const Switch = styled(FlexColumn)`
  justify-content: center;
`

const Button = styled.span`
  align-self: stretch;
  background-image: url(${({ src }: {src: string}) => src});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
  height: 10px;
  cursor: pointer;
`

const value = (val: number) => val < 10 ? `0${val}` : `${val}`

// TODO: move to standalone package
const TimePicker = (props: Props) => {
  const [hours, setHours] = useState(props.hours)
  const [minutes, setMinutes] = useState(props.minutes)

  // TODO: refactor these functions, move it to separate hooks
  const increment = ({ minutesValue, hoursValue, step }: { minutesValue?: number, hoursValue?: number, step: number}) => {
    const newTime = {
      hours,
      minutes,
    }
    if (typeof hoursValue !== 'undefined') {
      if (step > 23) {
        throw Error('Hours step cannot be larger than 23')
      }
      const newHours = hoursValue + step
      if (newHours > 23) {
        newTime.hours = 0
      } else {
        newTime.hours = newHours
      }
      setHours(newTime.hours)
    }
    if (typeof minutesValue !== 'undefined') {
      if (step > 59) {
        throw Error('Minutes step cannot be larger than 59')
      }
      const newMinutes = minutesValue + step
      if (newMinutes > 59) {
        // TODO: increment hours
        newTime.minutes = 0
      } else {
        newTime.minutes = newMinutes
      }
      setMinutes(newTime.minutes)
    }
    props.onChange(newTime)
  }

  const decrement = ({ minutesValue, hoursValue, step }: { minutesValue?: number, hoursValue?: number, step: number}) => {
    const newTime = {
      hours,
      minutes,
    }
    if (typeof hoursValue !== 'undefined') {
      if (step > 23) {
        throw Error('Hours step cannot be larger than 23')
      }
      const newHours = hoursValue - step
      if (newHours < 0) {
        newTime.hours = 23
      } else {
        newTime.hours = newHours
      }
      setHours(newTime.hours)
    }
    if (typeof minutesValue !== 'undefined') {
      if (step > 59) {
        throw Error('Minutes step cannot be larger than 59')
      }
      const newMinutes = minutesValue - step
      if (newMinutes < 0) {
        // TODO: decrement hours
        newTime.minutes = 60 - step
      } else {
        newTime.minutes = newMinutes
      }
      setMinutes(newTime.minutes)
    }
    props.onChange(newTime)
  }

  useEffect(() => {
    setHours(props.hours)
    setMinutes(props.minutes)
  }, [props])

  return (
    <Container>
      <Switch>
        <Button src={up} onClick={() => increment({ hoursValue: hours, step: props.hoursStep || 1 })}/>
        <Input
          type="text"
          value={value(hours)}
          disabled
        />
        <Button src={down} onClick={() => decrement({ hoursValue: hours, step: props.hoursStep || 1 })}/>
      </Switch>
      <div>:</div>
      <Switch>
        <Button src={up} onClick={() => increment({ minutesValue: minutes, step: props.minutesStep || 1 })}/>
        <Input
          type="text"
          value={value(minutes)}
          disabled
        />
        <Button src={down} onClick={() => decrement({ minutesValue: minutes, step: props.minutesStep || 1 })}/>
      </Switch>
    </Container>
  )
}

export default TimePicker
