import styled, { css } from 'styled-components'
import { Theme } from '@ui'
import { media } from '@ui/_common'

const Secondary = css`
  color:  ${({ theme }: {theme: Theme}) => theme.colors.gray1};
  line-height: 1.1rem;
  font-size: ${({ theme }: {theme: Theme}) => theme.sizes.s}rem;
`

export const Title = styled.h1`
  font-size: ${({ theme }: {theme: Theme}) => theme.sizes.l}rem;
  font-weight: 700;
  font-family: 'OpenSansBold', sans-serif;
  position: relative;

  ${media.small} {
    font-size: ${({ theme }: {theme: Theme}) => theme.sizes.s}rem;
  }
`

export const SubTitle = styled.h2`
  font-size: ${({ theme }: {theme: Theme}) => theme.sizes.m}rem;
  font-weight: 600;
  font-family: 'OpenSansSemibold', sans-serif;
`

export const TitleXl = styled(Title)`
  font-size: ${({ theme }: {theme: Theme}) => theme.sizes.xl}rem;

  ${media.small} {
    font-size: ${({ theme }: {theme: Theme}) => theme.sizes.l}rem;
  }
`

export const SecondarySpan = styled.span`
 ${Secondary};
`

export const SecondaryButton = styled(SecondarySpan)`
  cursor: pointer;
`

export const SecondaryText = styled.p`
  ${Secondary};
  margin: 2rem 0;

  ${media.small} {
    margin: 1rem 0;
  }
`

export const BoldText = styled.strong`
  font-size: ${({ theme }: {theme: Theme}) => theme.sizes.s}rem;
  font-weight: 600;
  font-family: 'OpenSansSemibold', sans-serif;
  margin: .3rem;

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const SuccessMessage = styled.div`
  font-size: .9rem;
  color: ${({ theme }: {theme: Theme}) => theme.colors.green2};
  margin: .5rem 0;
`
