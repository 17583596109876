import axios, { AxiosInstance } from 'axios'
import config from 'config'

interface Response {
  countryCode: string
}

class IPApi {
  private api: AxiosInstance

  public constructor(apiUrl: string) {
    this.api = axios.create({
      baseURL: apiUrl,
    })
  }

  public async check(): Promise<Response> {
    const response = await this.api.get('/')
    if (!response.data) {
      throw new Error('Failed to check IP')
    }
    return {
      countryCode: response.data.alpha2,
    }
  }
}

export default new IPApi(config.ipApiUrl)
