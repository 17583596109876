import React, { Fragment, useContext, useMemo } from 'react'
import Assets from '@ui/assets'
import config from 'config'
import {
  BodyCell,
  ColumnTitle,
  Container,
  HeaderCell,
  LoaderContainer,
  RowTitle,
  Table,
  Tbody,
  Thead,
} from 'components/Schedule'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import { SchedulePlan, SchedulePlanRange } from 'services/api/responses'
import {
  DAY_TIME_UNITS,
  getPlanDayTo,
  getPlanTimeUnitTo,
  remapPlanToVerticalRows,
} from 'containers/Schedule/ScheduleCalendar/utils'
import { timeUnitsToTime } from 'utils/time'

interface RowProps {
  timeUnit: number
  removePlan: (deleteRange: SchedulePlanRange) => void
  openPlanPopup: (popupDetails: SchedulePlanRange) => void
  timeUnitPlan: {
    enabled: boolean
    mode: string
  }[]
}

interface Props {
  weekPlan: SchedulePlan
  loading: boolean
  removePlan: (deleteRange: SchedulePlanRange) => void
  openPlanPopup: (popupDetails: SchedulePlanRange) => void
}

const Header = () => {
  const localeCtx = useContext(localeContext)
  const days = useMemo(
    () =>
      config.days.short.map(day => (
        <Fragment key={day}>
          <HeaderCell><ColumnTitle vertical>{__(day, localeCtx.lang)}</ColumnTitle></HeaderCell>
        </Fragment>
      ))
    , [localeCtx.lang],
  )

  return (
    <Thead>
      <tr>
        <HeaderCell/>
        {days}
      </tr>
    </Thead>
  )
}

const ScheduleRow = (props: RowProps) => (
  <tr>
    <BodyCell><RowTitle>{timeUnitsToTime(props.timeUnit)}</RowTitle></BodyCell>
    {
      props.timeUnitPlan.map(({ enabled, mode }, dayIndex) => (
        <BodyCell
          key={dayIndex}
          onClick={() => {
            if (enabled) {
              props.removePlan({
                dayFrom: dayIndex,
                timeUnitFrom: props.timeUnit,
                dayTo: dayIndex,
                timeUnitTo: props.timeUnit + 1, // TODO: get whole plan range
                mode,
              })
            } else {
              props.openPlanPopup({
                dayFrom: dayIndex,
                dayTo: getPlanDayTo(props.timeUnit, dayIndex),
                timeUnitFrom: props.timeUnit,
                timeUnitTo: getPlanTimeUnitTo(props.timeUnit),
                mode: 'auto',
              })
            }
          }}
          active={enabled}
          mode={mode}
        />))
    }
  </tr>
)

const Vertical = (props: Props) => (
  <Container>
    <Table>
      <Header/>
      <Tbody>
        {
          remapPlanToVerticalRows(props.weekPlan).map((timeUnitPlan, timeUnit) => (
            <ScheduleRow
              key={timeUnit}
              timeUnit={timeUnit}
              timeUnitPlan={timeUnitPlan}
              removePlan={props.removePlan}
              openPlanPopup={props.openPlanPopup}
            />
          ))
        }
      </Tbody>
    </Table>
    <Table shadow vertical>
      <Tbody>
        {
          Array(DAY_TIME_UNITS).fill(0).map((_, timeUnit) =>
            <tr key={timeUnit}>
              <BodyCell/>
              {config.days.short.map(day => <BodyCell key={day}/>)}
            </tr>)
        }
      </Tbody>
    </Table>
    {props.loading && <LoaderContainer><Assets.Loader/></LoaderContainer>}
  </Container>
)


export default Vertical
