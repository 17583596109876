import React, { createContext, useState } from 'react'
import storage from 'services/storage'
import config from 'config'

export interface LocaleContext {
  lang: string
  setLanguage: (lang: string) => void
}

const LANG_KEY = 'lang'

const getValidLang = (lang: string|null) => {
  if (!lang) {
    return config.locale.default
  }
  if (!config.locale.available.includes(lang)) {
    // eslint-disable-next-line no-console
    console.error(`Unsupported locale "${lang}"`)
    return config.locale.default
  }
  return lang
}

const defaultCtx = {
  lang: getValidLang(storage.permanent.getItem(LANG_KEY)),
  setLanguage: () => {},
}

const localeContext = createContext<LocaleContext>(defaultCtx)

export const LocaleProvider = ({ children }: {children: React.ReactNode}) => {
  const [lang, updateLang] = useState<string>(defaultCtx.lang)

  const ctx: LocaleContext = {
    lang,
    setLanguage: (newLang: string) => {
      const langToSave = getValidLang(newLang)
      storage.permanent.setItem(LANG_KEY, langToSave)
      updateLang(langToSave)
    },
  }

  return (
    <localeContext.Provider value={ctx}>
      {children}
    </localeContext.Provider>
  )
}

export default localeContext
