import React from 'react'
import { Popup, PopupText } from '@ui/popup'

interface Props {
  open: boolean
  onClose: () => void
  title: string
  text: string
}

const NotificationPopup = (props: Props) => (
  <Popup
    title={props.title}
    opened={props.open}
    onClose={props.onClose}
  >
    <PopupText>
      {props.text}
    </PopupText>
  </Popup>
)

export default NotificationPopup
