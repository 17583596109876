import React, { createContext, useState } from 'react'

interface ErrorMessage {
  msg: string
  opts: { autoHide: boolean }
}

export interface ErrorContext {
  errorMessage: ErrorMessage
  setErrorMessage: (msg: string, opts?: { autoHide: boolean }) => void
}

const defaultCtx = {
  errorMessage: { msg: '', opts: { autoHide: true } },
  setErrorMessage: () => {
  },
}

const errorContext = createContext<ErrorContext>(defaultCtx)

export const ErrorMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({ msg: '', opts: { autoHide: true } })

  const ctx: ErrorContext = {
    errorMessage,
    setErrorMessage: (msg: string, opts: { autoHide: boolean } = { autoHide: true }) => setErrorMessage({ msg, opts }),
  }

  return (
    <errorContext.Provider value={ctx}>
      {children}
    </errorContext.Provider>
  )
}

export default errorContext
