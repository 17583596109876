import styled from 'styled-components'

export const Input = styled.input`
  appearance: none;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  background: none;
  margin: 1rem 0;
  padding: .5rem;
  font-size: ${({ theme }) => theme.sizes.s}rem;
  transition: border-color 100ms linear;
  color: ${({ theme }) => theme.colors.black};
  display: block;
  width: 100%;
  box-sizing: border-box;
  
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray1};
  }
  
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`
