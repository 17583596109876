import { createGlobalStyle } from 'styled-components'
import { media } from '@ui/_common'

export interface Theme {
  colors: {
    primary: string
    green: string
    green2: string
    orange: string
    black: string
    yellow: string
    lightBlue: string
    lightBlue2: string
    red: string
    gray1: string
    gray2: string
    gray3: string
    gray4: string
    gray5: string
    white: string
    schedule: {
      min: string
      nom: string
      auto: string
      boost: string
    }
    new: string
    warning: string
    error: string
  }
  sizes: {
    xs: number
    s: number
    m: number
    l: number
    xl: number
  }
}

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    position: relative;
    height: 100%;
    ${media.medium} {
      height: auto;
      min-height: 100%;
    }
  }
  
  html {
    font-size: 18px !important;
  }
  
  
  body,
  input, button, select,
  [data-balloon]:after {
    font-family: 'OpenSans', sans-serif !important;
  }
`
