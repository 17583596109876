import React from 'react'
import styled from 'styled-components'
import {
  FlexContainer,
  FlexBetween,
  FlexColumn,
  FlexRowVerticalCenter,
  FlexBetweenVerticalCenter,
} from '@ui/layout'
import { BoldText, SecondaryText, SubTitle } from '@ui/text'
import { Toggle } from '@ui/toggle'
import { Tooltip } from '@ui/tooltip'
import { Card } from '@ui/card'
import editIcon from '@ui/assets/edit.svg'
import { media } from '@ui/_common'

interface ToggleRowProps {
  checked: boolean
  disabled?: boolean
  onChange: () => void
  title: string
  info: string
  children?: React.ReactNode
  loading?: boolean
}

const ToggleRowContainer = styled(FlexBetween)`
  margin: 1rem 0;
`

export const UnitToggleContainerOn = styled.span`
  font-weight: 600;
  position: relative;
  right: 10px;
`

export const UnitToggleContainerOff = styled.span`
  font-weight: 600;
  position: relative;
  right: -10px;
`

export const UnitName = styled(SubTitle)`
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    background-image: url(${editIcon});
    background-repeat: no-repeat;
    background-size: contain;
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    right: -20px;
    top: 4px;
  }
`

export const UnitNameContainer = styled(FlexBetween)`
  margin: 1.5rem 0;
  align-items: center;
  
  ${media.max(430)} {
    margin: .5rem 0;
    flex-direction: column;
    align-items: stretch;
    
    ${UnitName}:after {
      right: 0
    }
    
    & > ${FlexRowVerticalCenter} {
      justify-content: flex-end;
      margin: .7rem 0;
    }
  }
`

export const UnitModeContainer = styled(FlexColumn)`
  
`

export const CardTitle = styled(FlexRowVerticalCenter)`
  ${media.max(1200)} {
    flex-direction: column;
  }
`

export const CardValue = styled(BoldText)`
  text-align: center;
`

export const CardsContainer = styled(FlexContainer)`
  flex: 2;
  flex-wrap: wrap;
  
  ${Card} {
    flex: 0 0 calc(50% - 2rem);
    
    ${media.large} {
      padding: 1rem 0;
      ${FlexBetween} {
        flex-direction: column-reverse;
        align-items: center;
      }
      
      ${CardTitle} {
        color: ${({ theme }) => theme.colors.gray1};
        text-align: center;
      }
      
      ${CardValue} {
        font-size: ${({ theme }) => theme.sizes.l}rem;
        margin-bottom: 1rem;
      }
    }
    
    ${media.xsmall} {
      flex: 0 0 calc(50% - 1rem);
      margin: .5rem;
    }
  }
`

export const FiltersContainer = styled(FlexColumn)`
  flex: 1;
  padding: .5rem 0;
  justify-content: flex-start;
`

export const FilterItemContainer = styled(FlexBetweenVerticalCenter)`
  & > * {
    flex: 1
  }
`

export const FilterValue = styled(BoldText)`
  text-align: right;
  flex: 1;
`

export const FilterTitle = styled(FlexRowVerticalCenter)`
  font-size: ${({ theme }) => theme.sizes.xs}rem;
  transition: all 150ms linear;
  ${({ scaled }: {scaled?: boolean}) => scaled ? 'transform: scale(1.1)' : ''};
  white-space: nowrap;
  flex: 2;
`

export const UnitInfoContainer = styled(FlexContainer)`
  align-items: stretch;
  ${media.large} {
    flex-direction: column;
    
    ${FiltersContainer} {
      padding: 1rem;
    }
  }
`

export const HouseWrapper = styled.object`
  max-height: 60vh;
  align-self: center;
  
  @media all and (max-height: 950px) {
    max-height: 50vh;
  }
  
  @media all and (max-height: 750px) {
    max-height: 45vh;
  }
  
  @media all and (max-height: 700px) {
    max-height: 40vh;
  }
  
  ${media.small} {
    display: none;
  }
`

export const DashboardPanelTitle = styled(BoldText)`
  font-size: ${({ theme }) => theme.sizes.xl}rem;
  margin: 0 0 2rem 0;
  display: block;
  
  ${media.medium} {
    display: none;
  }
`

export const ModeDescription = styled(SecondaryText)`
  min-height: 3.5rem;
  margin-bottom: 0 !important;
`

export const DashboardContainer = styled.div`
  ${({ enabled }: { enabled: boolean }) => !enabled && `
    filter: grayscale(.7);
  `};
`

export const ToggleRow = (props: ToggleRowProps) => (
  <ToggleRowContainer>
    <BoldText>{props.title}</BoldText>
    <FlexBetweenVerticalCenter>
      {props.children}
      <Toggle checked={props.checked} onChange={props.onChange} disabled={props.disabled} loading={props.loading}/>
      <Tooltip position="right" message={props.info} title={props.title}/>
    </FlexBetweenVerticalCenter>
  </ToggleRowContainer>
)

export const OfflineUnit = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  & > div {
    background: white;
    padding: 2rem 1rem;
    border-radius: 5px;
  }
`
