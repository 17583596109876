import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Theme } from '@ui'
import { SelectElement } from '@ui/select'
import { FlexBetween, FlexContainer, FlexRowVerticalCenter } from '@ui/layout'
import { SecondarySpan, SecondaryText } from '@ui/text'
import { media } from '@ui/_common'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import { ScheduleModeDnd } from 'containers/Schedule/ScheduleCalendar/utils'

const planColor = (mode: string|undefined, theme: Theme) => {
  switch (mode) {
    case 'min': return theme.colors.schedule.min
    case 'auto': return theme.colors.schedule.auto
    case 'nom': return theme.colors.schedule.nom
    case 'boost': return theme.colors.schedule.boost
    default: return theme.colors.primary
  }
}

export const Container = styled.div`
  position: relative;
  margin: 3rem 0 0 1.2rem;
`

export const Scrollable = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`

export const LoaderContainer = styled.div`
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, .8);
`

export const TableCell = css`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-right: 0;
  border-left: 0;
  box-sizing: border-box;
  min-width: 30px;
`

export const HeaderCell = styled.th`
  ${TableCell};
`

export const ColumnTitle = styled.span`
  position: absolute;
  left: ${({ vertical }: {vertical?: boolean}) => vertical ? '.5rem' : '-1.2rem'};
  top: -2.5rem;
  font-family: 'OpenSansSemibold',serif;
  font-weight: 600;
  font-size: ${({ theme }) => theme.sizes.xs}rem;
  
  ${media.medium} {
    top: -1rem;
  }
`

export const RowTitle = styled.span`
  position: absolute;
  left: -1.2rem;
  top: 1.5rem;
  font-family: 'OpenSansSemibold',serif;
  font-weight: 600;
  font-size: ${({ theme }) => theme.sizes.xs}rem;
  
  ${media.medium} {
    top: -.4rem;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  position: relative;
  z-index: 2;
  
  ${({ shadow, theme, vertical }: {shadow?: boolean, theme: Theme, vertical?: boolean}) => {
    if (shadow) {
      return `
        position: absolute;
        top: .5rem;
        z-index: 1;
        td {
          border: 1px solid ${theme.colors.gray1};
          ${!vertical ? `
          &:nth-of-type(even) {
            border-right: 0;
          }` : ''}
          &:nth-of-type(odd) {
            border-left: 0;
          }
        }
      `
    }
    return `
      td {
        border: none;
      }
    `
  }};
  
  ${media.medium} {
    tr:nth-of-type(even) {
      ${RowTitle} {
        ${({ theme }) => `
          color: ${theme.colors.gray3};
      `};
      }
    }
  }
`

export const BodyCell = styled.td`
  ${TableCell};
  padding: .5rem;
  height: 60px;
  vertical-align: top;
  
  ${media.medium} {
    height: 30px;
  }
  
  &:first-of-type {
    width: 10px;
    vertical-align: middle;
    
    ${media.medium} {
      width: 0;
      border: none;
    }
  }
  
  ${({ active, theme, mode }: { active?: boolean, mode?: string, theme: Theme }) =>
    active
      ? `
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        background: ${planColor(mode, theme)};
        border-radius: 10px;
        ${mode === ScheduleModeDnd ? `
          background: none;
          border: 2px dashed ${planColor(mode, theme)};
        ` : ''};
        
        ${media.medium} {
          top: 0;
          right: 5px;
          bottom: 0;
          left: 5px;
          border-radius: 0;
        }
      }
      &:hover {
        opacity: .8;
        &:after {
          content: '\\00d7';
          font-size: 1.5rem;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 10px;
          right: 10px;
          top: 10px;
          bottom: 10px;
          color: ${mode === ScheduleModeDnd ? theme.colors.primary : '#fff'};
        }
      }
      ` : `
        span.drag {
          display: block;
          width: 100%;
          height: 100%;
          border: 1px dashed ${theme.colors.gray2};
          border-radius: 10px;
          margin-right: -.5rem;
        }
      `
}
`

export const Thead = styled.thead`
  ${HeaderCell} {
    height: 0;
    padding-bottom: 8px;
    border-bottom: none;
    @-moz-document url-prefix() {
      padding-bottom: 4px;
    }
  }
`

export const Tbody = styled.tbody`
  tr:first-of-type ${BodyCell} {
    border-top: none;
  }
`

export const DateTimeSelect = styled(FlexContainer)`
  align-items: center;
  margin: .5rem 0;
  ${SecondarySpan}:last-of-type {
    margin-left: 1rem;
  }
  ${SelectElement} {
    color: ${({ theme }) => theme.colors.black};
    font-family: 'OpenSansSemibold', sans-serif;
    font-weight: 600;
  }
`


export const ModeSelect = styled(FlexRowVerticalCenter)`
  ${SelectElement} {
    color: ${({ theme }) => theme.colors.black};
    font-family: 'OpenSansSemibold', sans-serif;
    font-weight: 600;
  }
`

const Mode = styled(FlexRowVerticalCenter)`
  position: relative;
  margin-right: 2rem;
  font-size: ${({ theme }) => theme.sizes.xs}rem;
  &:first-of-type {
    margin-left: 1rem;
  }
  
  &:before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    left: -1rem;
    border-radius: 10px;
    background-color: ${({ mode, theme }: { mode: string, theme: Theme }) => planColor(mode, theme)}
  }
  
  ${media.small} {
    margin-left: 0;
  }
`

const ModesContainer = styled(FlexContainer)`
  ${media.medium} {
    flex-direction: column;
    margin-left: 1rem;
    
    ${Mode} {
      margin: .3rem 0;
      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
  }
`

export const ModesLegend = (props: {modes: string[]}) => {
  const localeCtx = useContext(localeContext)

  return (
    <ModesContainer>
      {
        props.modes.map(mode => <Mode mode={mode} key={mode}>{__(`schedule.modes.${mode}`, localeCtx.lang)}</Mode>)
      }
    </ModesContainer>
  )
}

export const BottomContainer = styled(FlexBetween)`
  margin-bottom: 1rem;
  ${media.medium} {
    flex-direction: column;
  }
`

export const ScheduleDescription = styled(SecondaryText)`
  margin-top: 0;
  ${media.medium} {
    margin: 0 0 -1rem 0;
  }
`

export const ScheduleButtonDescription = styled(SecondaryText)`
  margin: 2rem 0 1rem;
  ${media.medium} {
    font-size: .8rem;
  }
`
