import React, { useState, useEffect, useContext } from 'react'
import { withTheme } from 'styled-components'
import { SecondarySpan } from '@ui/text'
import { FlexBetween, FlexBetweenVerticalCenter, FlexRowVerticalCenter } from '@ui/layout'
import { Toggle } from '@ui/toggle'
import Assets from '@ui/assets'
import { Confirm } from '@ui/confirm'
import { Tooltip } from '@ui/tooltip'
import { UnitName, UnitNameContainer, UnitToggleContainerOff, UnitToggleContainerOn } from 'components/Dashboard'
import UnitNamePopup from 'containers/Dashboard/Popup/UnitName'
import api from 'services/api'
import { Mode } from 'services/api/inputs'
import localeContext from 'contexts/locale'
import { __ } from 'services/locale'
import { useConfirm } from 'hooks/useConfirm'
import dataContext from 'contexts/data'
import { apiErrorHandler } from 'utils/error'
import errorContext from 'contexts/error'
import authContext from 'contexts/auth'
import { Theme } from '@ui'
import unitAwaiter from 'services/unit-awaiter'

interface Props {
  turnedOn: boolean
  showTurnOnSwitch: boolean
  statusChangeInProgress: boolean
  onStatusChange: (isChanging: boolean) => void
  name: string
  type: string
  theme: Theme
}

const Unit = (props: Props) => {
  const localeCtx = useContext(localeContext)
  const dataCtx = useContext(dataContext)
  const errorCtx = useContext(errorContext)
  const authCtx = useContext(authContext)

  const [turnedOn, toggleUnit] = useState<boolean>(false)
  const [name, changeName] = useState<string>(props.name)
  const [namePopupOpen, toggleNamePopup] = useState<boolean>(false)

  const { confirmOpened, openConfirm } = useConfirm()

  useEffect(() => {
    changeName(props.name)
    try {
      if (unitAwaiter.check('unit-stop', !props.turnedOn)) {
        toggleUnit(props.turnedOn)
        props.onStatusChange(false)
      }
    } catch (err) {
      apiErrorHandler(errorCtx, localeCtx, authCtx)(err)
      toggleUnit(props.turnedOn)
      props.onStatusChange(false)
    }
  }, [props]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <UnitNameContainer>
        <UnitName onClick={() => props.turnedOn && !props.statusChangeInProgress && toggleNamePopup(true)}>
          {name}&nbsp;
          { props.type && <SecondarySpan>({props.type})</SecondarySpan>}
        </UnitName>
        <FlexRowVerticalCenter>
          { props.showTurnOnSwitch && (
            <>
              { props.statusChangeInProgress && <Assets.SmallLoader/> }
              <FlexBetween>
                <FlexBetweenVerticalCenter>
                  <UnitToggleContainerOff>OFF</UnitToggleContainerOff>
                  <Toggle
                    checked={turnedOn}
                    disabled={props.statusChangeInProgress}
                    onChange={async () => {
                      if (turnedOn) {
                        // show confirm dialog if the user really want to turn off the unit
                        openConfirm(true)
                        return
                      }
                      unitAwaiter.register('unit-stop', false)
                      await api.enableMode(Mode.unitStopped, false)
                      toggleUnit(true)
                      props.onStatusChange(true)
                    }}
                    enabledColor={props.theme.colors.green2}
                  />
                  <UnitToggleContainerOn>ON</UnitToggleContainerOn>
                </FlexBetweenVerticalCenter>
                <Tooltip
                  position="right"
                  message={__(`dashboard.unitinfo.tooltip-${turnedOn ? 'on' : 'off'}`, localeCtx.lang)}
                  title={name}
                />
              </FlexBetween>
            </>
          )}
        </FlexRowVerticalCenter>
      </UnitNameContainer>
      <UnitNamePopup
        open={namePopupOpen}
        value={name}
        onClose={() => toggleNamePopup(false)}
        submit={async (newName: string) => {
          if (newName.trim() !== '' && newName !== name) {
            changeName(newName)
            toggleNamePopup(false)
            dataCtx.set({
              ...dataCtx.data,
              unit: {
                ...dataCtx.data.unit,
                name: newName,
              },
            })
            await api.updateUnitName(newName).catch(apiErrorHandler(errorCtx, localeCtx, authCtx))
          }
        }}
      />
      <Confirm
        opened={confirmOpened}
        onClose={() => openConfirm(false)}
        message={__('dashboard.confirm.turnoff', localeCtx.lang)}
        onConfirm={async () => {
          unitAwaiter.register('unit-stop', true)
          await api.enableMode(Mode.unitStopped, true)
          toggleUnit(false)
          props.onStatusChange(true)
        }}
      />
    </>
  )
}

export default withTheme(Unit)
