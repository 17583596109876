import React from 'react'
import styled from 'styled-components'
import { Theme } from '@ui/index'
import Assets from '@ui/assets'

interface Props {
  checked: boolean
  disabled?: boolean
  loading?: boolean
  onChange: () => void
  enabledColor?: string
}

const ToggleContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
  margin: 0 1rem;

  // loader
  div {
    span {
      width: 15px;
      height: 15px;
      position: relative;
      left: -1.5rem;
    }
   }
`

const ToggleStyle = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.gray1};
  transition: 200ms;
  border-radius: 34px;
  display: flex;
  align-items: center;
  
  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,.3);
    transition: 200ms;
    border-radius: 50%;
  }
  
  ${({ disabled }: { disabled: boolean }) => disabled ? `
    opacity: .5;
    cursor: default;
  ` : ''}
`

const ToggleInput = styled.input`
  display: none;
   
  ${({ theme, enabledColor }: { theme: Theme, enabledColor?: string}) => `
    &:focus + ${ToggleStyle} {
      box-shadow: 0 0 1px ${enabledColor || theme.colors.primary};
    }
    
    &:checked + ${ToggleStyle} {
      background-color: ${enabledColor || theme.colors.primary};
    }
  `}
  
   &:checked + ${ToggleStyle}:before {
    transform: translateX(24px);
  }
`

export const Toggle = (props: Props) => (
  <ToggleContainer>
    {props.loading && <div><Assets.Loader/></div>}
    <ToggleInput
      type="checkbox"
      checked={props.checked}
      enabledColor={props.enabledColor}
      onChange={() => {
        if (!props.disabled && !props.loading) {
          props.onChange()
        }
      }}
    />
    <ToggleStyle disabled={Boolean(props.loading || props.disabled)}/>
  </ToggleContainer>
)
