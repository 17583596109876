import React, { useContext } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import CookieConsent from 'react-cookie-consent'
import { GlobalStyle } from '@ui'
import theme from 'theme'
import Dashboard from 'containers/Dashboard'
import Notifications from 'containers/Notifications'
import Authenticated from 'middlewares/Authenticated'
import localeContext, { LocaleProvider } from 'contexts/locale'
import { AppContainer } from 'containers'
import ErrorMessage from 'components/ErrorMessage'
import { ErrorMessageProvider } from 'contexts/error'
import { AuthProvider } from 'contexts/auth'
import { GlobalDataProvider } from 'contexts/data'
import Profile from 'containers/Profile'
import Locale from 'middlewares/Locale'
import { __ } from 'services/locale'

const App: React.FC = () => {
  const localeCtx = useContext(localeContext)
  return (
    <>
      <CookieConsent
        buttonText={__('general.understand', localeCtx.lang)}
        buttonStyle={{ background: theme.colors.primary, color: '#fff', borderRadius: '.3rem' }}
        style={{ fontSize: '.75rem' }}
      >
        {__('general.cookies', localeCtx.lang)}
      </CookieConsent>
      <Authenticated>
        <AppContainer>
          {
            (data: any) => (
              <Switch>
                <Route path="/" exact render={props => <Dashboard {...props} dashboard={data.dashboard} showTurnOnSwitch={data.showTurnOnSwitch} />}/>
                <Route path="/notifications" exact component={Notifications}/>
                <Route path="/profile" exact component={Profile}/>
                <Redirect to="/"/>
              </Switch>
            )
          }
        </AppContainer>
      </Authenticated>
    </>
  )
}

export default () => (
  <ThemeProvider theme={theme}>
    <GlobalDataProvider>
      <LocaleProvider>
        <AuthProvider>
          <ErrorMessageProvider>
            <Locale>
              <GlobalStyle/>
              <ErrorMessage/>
              <Router>
                <App/>
              </Router>
            </Locale>
          </ErrorMessageProvider>
        </AuthProvider>
      </LocaleProvider>
    </GlobalDataProvider>
  </ThemeProvider>
)
