type EventHandler = (data: any) => void

export enum EventType {
  NetworkRequestSucceeded = 'NetworkRequestSucceeded',
}

class EventEmitter {
  private callbacks: { [key: string]: EventHandler[] }

  public constructor() {
    this.callbacks = {}
  }

  on(evt: EventType, cb: EventHandler) {
    if (!this.callbacks[evt]) {
      this.callbacks[evt] = []
    }
    this.callbacks[evt].push(cb)
  }

  emit(evt: EventType, data?: any) {
    const cbs = this.callbacks[evt]
    if (cbs) {
      cbs.forEach(cb => cb(data))
    }
  }

  removeListener(evt: EventType, cb: EventHandler) {
    this.callbacks[evt] = this.callbacks[evt].filter(handler => handler !== cb)
  }
}


export default new EventEmitter()
