import React, { createContext, useState } from 'react'
import { AnyObject } from 'utils'

export interface DataContext {
  data: AnyObject
  set: (data: AnyObject) => void
}

const defaultCtx = {
  data: {
    unit: {
      name: '-',
      type: '',
    },
    lastNotification: null,
    // get current timestamp in seconds
    unitTimestamp: new Date().getTime() / 1000,
  },
  title: '',
  set: () => {},
}

const dataContext = createContext<DataContext>(defaultCtx)

export const GlobalDataProvider = ({ children }: {children: React.ReactNode}) => {
  const [data, setData] = useState<AnyObject>(defaultCtx.data)

  const ctx: DataContext = {
    data,
    set: setData,
  }

  return (
    <dataContext.Provider value={ctx}>
      {children}
    </dataContext.Provider>
  )
}

export default dataContext
