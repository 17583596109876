import React, { useContext, useState } from 'react'
import Assets from '@ui/assets'
import { ScreenSize } from '@ui/_common'
import { FlexColumnBetween, MediaQuery } from '@ui/layout'
import { Popup } from '@ui/popup'
import { Button } from '@ui/buttons'
import { SecondaryText } from '@ui/text'
import { __ } from 'services/locale'
import localeContext from 'contexts/locale'

interface Props {
  position: 'up' | 'left' | 'down' | 'right'
  title: string
  message: string
}

export const Tooltip = (props: Props) => {
  const [tooltipOpen, toggle] = useState(false)
  const localeCtx = useContext(localeContext)
  return (
    <>
      <MediaQuery minWidth={ScreenSize.M}>
        <Assets.InfoIcon
          data-balloon={props.message}
          data-balloon-pos={props.position}
          data-balloon-length="large"
        />
      </MediaQuery>
      <MediaQuery maxWidth={ScreenSize.M}>
        <Assets.InfoIcon
          onClick={() => toggle(true)}
        />
        <Popup
          title={props.title}
          opened={tooltipOpen}
          onClose={() => toggle(false)}
        >
          <FlexColumnBetween>
            <SecondaryText>{props.message}</SecondaryText>
            <Button onClick={() => toggle(false)}>{__('general.understand', localeCtx.lang)}</Button>
          </FlexColumnBetween>
        </Popup>
      </MediaQuery>
    </>
  )
}
